import {postData} from "../utils/Server";
import Endpoints from "../utils/Endpoints";
import {onServiceDone, parseResponse} from "./Utils";


const getConfig = (res) => {
    return {
        public_key: res.public_key,
        tx_ref: res.ID,
        amount: 10.00,
        currency: 'KES',
        country: 'KE',
        payment_options: 'card,mobilemoney',
        customer: {
            email: res.customer_email,
            name: res.customer_name,
        },
        customizations: {
            title: res.title,
            description: res.description,
            logo: res.custom_logo,
        },
    };
}

const initiatePayment = ({
                             account_number = "",
                             account_type = "",
                             done = onServiceDone
                         }) => {
    let data = new FormData();
    data.append("account_number", account_number)
    data.append("account_type", account_type)

    parseResponse(postData(Endpoints.INITIATE_PAYMENT, data), done)
}

export default {initiatePayment, getConfig}