import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Box, Button, Card, CardContent, Container, Divider, makeStyles, TextField, Typography} from '@material-ui/core';
import Page from 'src/components/Page';
import {changePageTitle} from "../../utils/Common";
import AuthService from "../../services/AuthService";
import {ERROR, Notify} from "../../utils/Notify";
import {useGlobal} from "../../utils/Store";
import ProgressView from "../../components/ProgressView";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const RegisterView = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const {state} = useLocation()

    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false)
    const [values, setValues] = useState({
        email: '',
        fullName: ''
    });
    const [phone, setPhone] = useState('')

    useEffect(() => {
        if (state !== undefined && state !== null) {
            alert(state.phone)
            setPhone(state.phone)
        } else {
            navigate("/")
        }

    }, [])


    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };


    const registerUserAction = () => {
        setIsLoading(true)
        AuthService.registerUser({
            email: values.email,
            name: values.fullName,
            phone: phone,
            done: (res, err) => {
                setIsLoading(false)
                if (err === null && res !== null) {
                    if (res.success === 1) {
                        globalActions.setCurrentPhone(phone)
                        navigate('/verify')
                    } else {
                        Notify(res.message, ERROR)
                    }

                } else {
                    Notify(err, ERROR)
                }
            }
        })
    }

    return (
        <Page
            className={classes.root}
            title={changePageTitle('Register')}
        >
            <Box
                display="flex"
                flexDirection="column"
                height="100%"
                justifyContent="center"
            >
                <Container maxWidth="sm">

                    <Card>
                        <CardContent>
                            <Box>
                                <Typography
                                    color="textPrimary"
                                    variant="h3"
                                    gutterBottom
                                >
                                    Complete your registration
                                </Typography>
                            </Box>

                            <Box mb={3}>
                                <Typography
                                    color="textSecondary"
                                    variant="body1"
                                >
                                    Fill in your details below to start requesting delivery services easily
                                </Typography>
                            </Box>

                            <TextField
                                fullWidth
                                label="Full name"
                                margin="normal"
                                name="fullName"
                                onChange={handleChange}
                                type="text"
                                value={values.fullName}
                                variant="outlined"
                            />
                            <TextField
                                fullWidth
                                label="Email"
                                margin="normal"
                                name="email"
                                onChange={handleChange}
                                type="text"
                                value={values.email}
                                variant="outlined"
                            />
                        </CardContent>
                        <Divider/>
                        <Box
                            display="flex"
                            justifyContent="flex-start"
                            p={2}
                        >
                            {isLoading ?
                                <ProgressView showBackDrop={true} loading={isLoading}/>
                                : <Button
                                    color="primary"
                                    disabled={false}
                                    size="large"
                                    variant="contained"
                                    onClick={registerUserAction}
                                >
                                    Proceed
                                </Button>}

                            <Button
                                size="large"
                                onClick={() => navigate("/")}
                            >
                                CHANGE PHONE NUMBER
                            </Button>
                        </Box>
                    </Card>
                </Container>
            </Box>
        </Page>
    );
};

export default RegisterView;
