import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {Box, Card} from '@material-ui/core';
import BusinessFragment from "../../components/BusinessFragment";
import BillingFragment from "../../components/BillingFragment";


const ListView = ({className, ...rest}) => {
    return (
        <Card
            {...rest}
        >
            <PerfectScrollbar>
                <BillingFragment/>
            </PerfectScrollbar>
        </Card>
    );
};

export default ListView;
