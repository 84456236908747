import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {makeStyles} from "@material-ui/styles";
import {Backdrop} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const ProgressView = ({loading = true, showBackDrop = false, ...rest}) => {
    const classes = useStyles();

    return (
        <>
            {
                showBackDrop ? <Backdrop className={classes.backdrop} open={loading} onClick={() => {
                }}>
                    <CircularProgress color="inherit"/>
                </Backdrop> : <CircularProgress size={25} color={"primary"} {...rest}/>
            }
        </>


    )
};

export default ProgressView;