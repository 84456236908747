import React, {useEffect, useState} from 'react';
import {Box, Button, Card, CardContent, Container, TextField} from '@material-ui/core';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import 'react-vertical-timeline-component/style.min.css';
import PageContainer from "../../components/PageContainer";
import CorporateService from "../../services/CorporateService";
import {ERROR, Notify, SUCCESS} from "../../utils/Notify";
import ProgressView from "../../components/ProgressView";


const NewDepartment = () => {
    const navigate = useNavigate();
    const {state} = useLocation()

    const [data, setID] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [name, setName] = useState('')
    const [budget, setBudget] = useState('')
    const [invoiceEmail, setInvoiceEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')

    useEffect(() => {
        loadDetails()
    }, [])

    const loadDetails = () => {
        if (state !== undefined && state !== null) {
            setID(state)
            return
        }

        navigate('/dashboard/corporate')
    }

    const add = () => {
        setIsLoading(true)

        CorporateService.addDepartment({
            contact_email: email,
            invoice_email: invoiceEmail,
            contact_phone: phone,
            budget: budget,
            name: name,
            businessID: state.business_id,
            done: (res, err) => {
                setIsLoading(false)
                if (err === null && res !== null) {
                    if (res.success === 1) {
                        Notify(res.data, SUCCESS)
                        navigate('/dashboard/business/' + state.business_id)
                    } else {
                        Notify(res.message, ERROR)
                    }

                } else {
                    Notify(err, ERROR)
                }
            }
        })
    }


    return (
        <PageContainer title={"Add new department"} toolBarTitle={"Add new department"}>
            <Box mt={3}>
                <Card elevation={5}>
                    <CardContent>
                        <Box>
                            <TextField
                                fullWidth
                                label="Department name"
                                onChange={e => setName(e.target.value)}
                                value={name}
                                variant="outlined"
                            />
                        </Box>

                        <Box mt={3}>
                            <TextField
                                fullWidth
                                label="Monthly budget"
                                onChange={e => setBudget(e.target.value)}
                                value={budget}
                                variant="outlined"
                            />
                        </Box>

                        <Box mt={3}>
                            <TextField
                                fullWidth
                                label="Email address to receive orders"
                                onChange={e => setInvoiceEmail(e.target.value)}
                                value={invoiceEmail}
                                variant="outlined"
                            />
                        </Box>

                        <Box mt={3}>
                            <TextField
                                fullWidth
                                label="Contact email address"
                                onChange={e => setEmail(e.target.value)}
                                value={email}
                                variant="outlined"
                            />
                        </Box>

                        <Box mt={3}>
                            <TextField
                                fullWidth
                                label="Contact phone number"
                                onChange={e => setPhone(e.target.value)}
                                value={phone}
                                variant="outlined"
                            />
                        </Box>

                        <Box my={2}>
                            {isLoading ?
                                <ProgressView/>
                                : <Button
                                    color="primary"
                                    disabled={false}
                                    size="large"
                                    variant="contained"
                                    onClick={add}
                                >
                                    Add
                                </Button>}
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </PageContainer>
    );
};

export default NewDepartment;
