import React, {useEffect, useRef, useState} from 'react';
import {Box, Button, Card, CardContent, Divider, Paper, TextField, Typography} from '@material-ui/core';
import {useNavigate} from "react-router-dom";
import 'react-vertical-timeline-component/style.min.css';
import PageContainer from "../../components/PageContainer";
import {ERROR, Notify, SUCCESS} from "../../utils/Notify";
import ProgressView from "../../components/ProgressView";
import SignatureCanvas from 'react-signature-canvas'
import {makeStyles} from "@material-ui/styles";
import Colors from "../../utils/Colors";
import RequestsService from "../../services/RequestsService";


const useStyles = makeStyles({
    root: {
        width: '100%',
        height: 200
    },
});

const ReceiveGoods = () => {
    const navigate = useNavigate();
    const classes = useStyles();


    const [isLoading, setIsLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [code, setCode] = useState('')
    const signPad = useRef(null)

    useEffect(() => {

    }, [])


    const receiveGoods = () => {
        if (signPad.current.isEmpty()) {
            Notify("Please sign below", ERROR)
            return
        }

        setIsLoading(true)

        let dataUrl = signPad.current.getTrimmedCanvas().toDataURL('image/png')
        let file = dataURLtoFile(dataUrl, 'signature.png')

        RequestsService.receiveGoods({
            file: file,
            code: code,
            done: (res, err) => {
                setIsLoading(false)
                if (err === null && res !== null) {
                    if (res.success === 1) {
                        Notify(res.data, SUCCESS)
                        navigate('/dashboard/')
                    } else {
                        Notify(res.message, ERROR)
                    }

                } else {
                    Notify(err, ERROR)
                }
            }
        })
    }

    function dataURLtoFile(dataUrl, filename) {
        let arr = dataUrl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, {type: mime});
    }


    return (
        <PageContainer title={"Receive goods"} toolBarTitle={"Receive goods"}>
            <Box mt={3}>
                <Card elevation={5}>
                    <CardContent>
                        <Box>
                            <Typography color="textSecondary" gutterBottom variant={"h5"}>
                                Please enter the code presented to you by the rider then sign below to confirm
                            </Typography>
                        </Box>

                        <Box mt={3}>
                            <TextField
                                fullWidth
                                label="Receiving code"
                                onChange={e => setCode(e.target.value)}
                                value={code}
                                variant="outlined"
                            />
                        </Box>
                        <Box mt={3}>
                            <Typography color="textSecondary" gutterBottom variant={"h5"}>
                                Please sign here below
                            </Typography>
                            <Paper variant={"outlined"} elevation={2}>
                                <SignatureCanvas ref={signPad}
                                                 canvasProps={{className: classes.root}}/>
                            </Paper>

                            <Box mt={1}>
                                <Button style={{backgroundColor: Colors.white, color: Colors.red_500}}
                                        onClick={() => {
                                            signPad.current.clear()
                                        }}>
                                    Clear signature
                                </Button>
                            </Box>
                        </Box>

                        <Box mt={3}>
                            <Divider/>
                        </Box>

                        <Box my={2}>
                            {isLoading ?
                                <ProgressView/>
                                : <Button
                                    color="primary"
                                    size="large"
                                    variant="contained"
                                    onClick={receiveGoods}
                                >
                                    Confirm
                                </Button>}
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </PageContainer>
    );
};

export default ReceiveGoods;
