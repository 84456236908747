import React from 'react';
import {Navigate} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import RegisterView from 'src/views/auth/RegisterView';
import VerifyView from "./views/auth/VerifyView";
import Strings from "./utils/Strings";
import MyHistory from "./views/requests/history";
import RequestDetails from "./views/requests/details";
import Corporate from "./views/corporate";
import BusinessDetails from "./views/corporate/BusinessDetails";
import DepartmentDetails from "./views/corporate/DepartmentDetails";
import ChildAccountDetails from "./views/corporate/ChildAccountDetails";
import NewChildAccount from "./views/corporate/NewChildAccount";
import NewDepartment from "./views/corporate/NewDepartment";
import NewBusiness from "./views/corporate/NewBusiness";
import Billing from "./views/billing";
import NewBillingAccount from "./views/billing/NewBillingAccount";
import SubAccountDetails from "./views/billing/SubAccountDetails";
import SuccessPage from "./views/payment/SuccessPage";
import Home from "./views/home";
import ReceiveGoods from "./views/home/ReceiveGoods";
import RequestDelivery from "./views/home/RequestDelivery";
import ConfirmDelivery from "./views/home/ConfirmDelivery";
import AccountSettings from "./views/settings";

const routes = [
    {
        path: Strings.DASHBOARD_URL,
        element: <DashboardLayout/>,
        children: [
            {path: "", element: <Navigate to={`/${Strings.DASHBOARD_URL}/home`}/>},
            {path: 'home', element: <Home/>},
            {path: 'receive', element: <ReceiveGoods/>},
            {path: 'request', element: <RequestDelivery/>},
            {path: 'confirm', element: <ConfirmDelivery/>},
            {path: 'billing', element: <Billing/>},
            {path: 'billing/new', element: <NewBillingAccount/>},
            {path: 'sub-account/:id', element: <SubAccountDetails/>},
            {path: 'corporate', element: <Corporate/>},
            {path: 'business/:id', element: <BusinessDetails/>},
            {path: 'business/new', element: <NewBusiness/>},
            {path: 'department/:id', element: <DepartmentDetails/>},
            {path: 'department/new', element: <NewDepartment/>},
            {path: 'child-account/:id', element: <ChildAccountDetails/>},
            {path: 'child-account/new', element: <NewChildAccount/>},
            {path: 'history', element: <MyHistory/>},
            {path: 'request/:id', element: <RequestDetails/>},
            {path: 'payment-success', element: <SuccessPage/>},
            {path: 'settings', element: <AccountSettings/>},
            {path: '*', element: <Navigate to="/404"/>}
        ]
    },
    {
        path: '/',
        element: <MainLayout/>,
        children: [
            {path: 'login', element: <LoginView/>},
            {path: 'register', element: <RegisterView/>},
            {path: 'verify', element: <VerifyView/>},
            {path: '404', element: <NotFoundView/>},
            {path: '/', element: <LoginView/>},
            {path: '*', element: <Navigate to="/404"/>}
        ]
    }
];

export default routes;
