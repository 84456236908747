import React, {useEffect, useState} from 'react';
import {Box, Button, Card, CardContent, Divider, Typography} from "@material-ui/core";
import NumberFormat from "react-number-format";
import Colors from "../utils/Colors";
import {ERROR, Notify, SUCCESS} from "../utils/Notify";
import CorporateService from "../services/CorporateService";
import {useNavigate} from "react-router-dom";
import ProgressView from "./ProgressView";

const ChildAccountOverviewFragment = ({className, item}) => {

    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {

    }, [])


    const updateStatus = active => {
        setIsLoading(true)
        CorporateService.updateChildAccountStatus({
            id: item.ID,
            active: active,
            done: (res, err) => {
                setIsLoading(false)
                if (err === null && res !== null) {
                    if (res.success === 1) {
                        Notify(res.data, SUCCESS)
                        navigate('/dashboard/department/' + item.department_id)
                    } else {
                        Notify(res.message, ERROR)
                    }

                } else {
                    Notify(err, ERROR)
                }
            }
        })
    }

    const resendInvitation = () => {
        setIsLoading(true)
        CorporateService.resendInvitation({
            id: item.ID,
            done: (res, err) => {
                setIsLoading(false)
                if (err === null && res !== null) {
                    if (res.success === 1) {
                        Notify(res.data, SUCCESS)
                    } else {
                        Notify(res.message, ERROR)
                    }

                } else {
                    Notify(err, ERROR)
                }
            }
        })
    }


    return (
        <Box p={2}>
            <Box mb={1}>
                <Typography variant={"body1"} color="textSecondary">
                    This month's bill as of today with {item.this_months_requests} requests
                </Typography>
                <NumberFormat value={item.this_month_string}
                              displayType={'text'}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              thousandSeparator={true}
                              prefix={'KES '}
                              renderText={value => <Typography variant="h2"
                                                               gutterBottom>{value}</Typography>}/>
            </Box>
            <Box mb={2}>
                <Divider/>
            </Box>
            <Typography variant={"h5"} color="textSecondary" gutterBottom>
                {item.active === 1 ? "ACTIVE" : "INACTIVE"}
            </Typography>
            <Box mb={2}>
                <Divider/>
            </Box>
            <Typography variant={"h5"} color="textSecondary" gutterBottom>
                {item.accepted === 1 ? "INVITATION ACCEPTED" : "INVITATION IS PENDING"}
            </Typography>
            <Box mb={2}>
                <Divider/>
            </Box>
            <Typography color="textSecondary" gutterBottom variant={"h5"}>
                Integrations
            </Typography>
            <Typography color="textSecondary" gutterBottom variant={"body1"}>
                Use this billing account to automatically make pick up requests without the need of an app. For example,
                if you own an e-commerce website, you can use this billing account to directly push your orders to
                Pick&amp;Drop for delivery.
            </Typography>
            <Typography color="textSecondary" gutterBottom variant={"body1"}>
                You can share the API Key below with your developer or reach out to us to start integration.
            </Typography>
            <Box mb={2}>
            </Box>
            <Typography color="textSecondary" gutterBottom variant={"h5"}>
                API Key
            </Typography>
            <Box mb={2}>
                <Divider/>
            </Box>
            <Typography variant={"body1"} gutterBottom style={{fontStyle: 'italic'}}>
                {item.api_key}
            </Typography>

            <Box mb={2} mt={2}>
                <Divider/>
            </Box>

            {item.accepted === 0 && !isLoading &&
            <Button
                style={{backgroundColor: Colors.black, color: Colors.white, marginRight: 10}}
                variant="contained"
                onClick={() => resendInvitation()}
            >
                Resend invitation
            </Button>
            }

            {item.active === 1 && !isLoading &&
            <Button
                style={{backgroundColor: Colors.red_400, color: Colors.white}}
                variant="contained"
                onClick={() => updateStatus(0)}
            >
                Deactivate account
            </Button>
            }

            {item.active === 0 && !isLoading &&
            <Button
                style={{backgroundColor: Colors.green_500, color: Colors.white}}
                variant="contained"
                onClick={() => updateStatus(1)}
            >
                Activate account
            </Button>
            }

            {isLoading &&
            <Box p={1}>
                <ProgressView/>
            </Box>
            }

            {item.admin_active === 0 &&
            <Box mt={2}>
                <Typography color="textSecondary" gutterBottom style={{fontStyle: 'italic'}}>
                    This account has been deactivated by support. Please contact support for more information.
                </Typography>
            </Box>
            }

        </Box>
    )
};


export default ChildAccountOverviewFragment;
