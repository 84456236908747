import React, {useEffect, useState} from 'react';
import RequestItem from "./RequestItem";
import {Box, Divider, Typography} from "@material-ui/core";
import ProgressView from "./ProgressView";
import CorporateService from "../services/CorporateService";
import BusinessItem from "./BusinessItem";
import ChildAccountItem from "./ChildAccountItem";

const ChildAccountsFragment = ({className, departmentID=0}) => {

    const [isLoading, setIsLoading] = useState(false)
    const [list, setList] = useState([])

    useEffect(() => {
        load()
    }, [])

    const load = () => {
        setIsLoading(true)
        CorporateService.fetchChildAccounts({
            departmentID: departmentID,
            done: (data, err) => {
                setIsLoading(false)
                if (err === null && data !== null) {
                    setList(data.data || [])
                }
            }
        })
    }

    return (
        <Box>
            {isLoading &&
            <Box style={{textAlign: 'center'}} p={10}>
                <ProgressView/>
            </Box>
            }

                {list.map((item, index) => <ChildAccountItem key={item.ID} item={item}/>)}

            {list.length === 0 && !isLoading &&
            <Box p={10}>
                <Typography color="textSecondary" gutterBottom variant={"h4"}>
                    No accounts found...
                </Typography>

                <Typography color="textSecondary" gutterBottom variant={"body1"}>
                   Add accounts under your departments here. Their deliveries will be billed on this department
                </Typography>
            </Box>
            }
        </Box>
    )
};


export default ChildAccountsFragment;
