import React from 'react';
import {Box} from '@material-ui/core';
import PageContainer from "../../components/PageContainer";
import ProfileEdit from "./ProfileEdit";
import PhotoEdit from "./PhotoEdit";


const AccountSettings = () => {

    return (
        <PageContainer title={"Settings"} showToolBar={true} toolBarTitle={"Settings"}>
            <Box mt={3}>
                <PhotoEdit/>
            </Box>

            <Box mt={3}>
                <ProfileEdit/>
            </Box>
        </PageContainer>
    );
};

export default AccountSettings;
