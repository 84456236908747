import React, {useEffect, useState} from 'react';
import {Box, Typography} from "@material-ui/core";
import ProgressView from "./ProgressView";
import CorporateService from "../services/CorporateService";
import SubAccountItem from "./SubAccountItem";

const BillingFragment = ({className}) => {

    const [isLoading, setIsLoading] = useState(false)
    const [list, setList] = useState([])

    useEffect(() => {
        load()
    }, [])

    const load = () => {
        setIsLoading(true)
        CorporateService.fetchChildAccountsForUser({
            done: (data, err) => {
                setIsLoading(false)
                if (err === null && data !== null) {
                    setList(data.data || [])
                }
            }
        })
    }

    return (
        <Box>
            {isLoading &&
            <Box style={{textAlign: 'center'}} p={10}>
                <ProgressView/>
            </Box>
            }
            <Box p={2}>
                {list.map((item, index) => <SubAccountItem key={item.ID} item={item}/>)}
            </Box>

            {list.length === 0 && !isLoading &&
            <Box p={10}>
                <Typography color="textSecondary" gutterBottom variant={"h4"}>
                    No accounts found...
                </Typography>

                <Typography color="textSecondary" gutterBottom variant={"body1"}>
                    Add your invited accounts here and use them for billing when making requests.
                </Typography>

                <Typography color="textSecondary" gutterBottom variant={"body1"}>
                    You can have accounts from multiple corporates.
                </Typography>
            </Box>
            }
        </Box>
    )
};


export default BillingFragment;
