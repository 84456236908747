import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    InputLabel,
    makeStyles,
    TextField, Typography
} from '@material-ui/core';
import {ERROR, Notify, SUCCESS} from "../../utils/Notify";
import AuthService from "../../services/AuthService";
import {useGlobal} from "../../utils/Store";
import {handleSaveUser} from "../../utils/Auth";
import ProgressView from "../../components/ProgressView";
import Endpoints from "../../utils/Endpoints";

const useStyles = makeStyles(({
    root: {},
    avatar: {
        width: 100,
        height: 100
    }
}));

const PhotoEdit = ({className, ...rest}) => {
    const classes = useStyles();

    const [globalState, globalActions] = useGlobal();
    const [loading, setIsLoading] = useState(false)
    const [file, setFile] = useState('')


    const handleChange = (event) => {

    };

    useEffect(() => {


    }, [globalState.currentUser])


    const updatePhoto = () => {
        setIsLoading(true)
        AuthService.updatePhoto({
            file: file,
            done: (res, err) => {
                setIsLoading(false)
                if (err === null && res !== null) {
                    if (res.success === 1) {
                        let user = globalState.currentUser
                        user.photo = res.data

                        handleSaveUser(globalActions, user)
                        setFile('')
                        Notify("Profile successfully updated", SUCCESS)
                    } else {
                        Notify(res.message, ERROR)
                    }

                } else {
                    Notify(err, ERROR)
                }
            }
        })
    }

    return (
        <form
            className={clsx(classes.root, className)}
            {...rest}
        >
            <Card>
                <CardHeader
                    subheader="Update your profile photo for riders to recognize you"
                    title="Profile photo"
                />
                <Divider/>
                <CardContent>
                    <Box>
                        <Box mb={2}>
                            <Avatar
                                className={classes.avatar}
                                src={Endpoints.SERVER_HOST + Endpoints.FILE_URL + globalState.currentUser.photo}
                            />
                        </Box>

                        <Box mt={2}>
                            <Divider/>
                        </Box>

                        <Box mt={2}>
                            <InputLabel>Select a new photo to use</InputLabel>
                            <Box mt={1}>
                                <label htmlFor="btn-upload">
                                    <input
                                        onChange={e => setFile(e.target.files[0])}
                                        type={"file"}
                                        style={{display: 'none'}}
                                        id="btn-upload"
                                    />
                                    <Button
                                        className="btn-choose"
                                        variant="outlined"
                                        component="span">
                                        Choose Photo
                                    </Button>
                                </label>
                            </Box>
                        </Box>

                        <Box mt={1}>
                            {file &&
                            <Box

                            >
                                <Box mt={2} mb={2}>
                                    <Avatar
                                        className={classes.avatar}
                                        src={URL.createObjectURL(file)}
                                    />
                                </Box>

                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={updatePhoto}
                                >
                                    Update profile photo
                                </Button>
                            </Box>
                            }
                        </Box>


                    </Box>
                </CardContent>
            </Card>

            <ProgressView loading={loading} showBackDrop={true}/>
        </form>
    );
};

PhotoEdit.propTypes = {
    className: PropTypes.string
};

export default PhotoEdit;
