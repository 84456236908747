import React, {useEffect} from 'react';
import {Link as RouterLink, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Avatar, Box, Divider, Drawer, Hidden, List, makeStyles, Typography} from '@material-ui/core';
import {
    Briefcase as CorporateIcon,
    CreditCard as BillingIcon,
    Home as HomeIcon,
    Settings as SettingsIcon,
    Truck as TruckIcon
} from 'react-feather';
import NavItem from './NavItem';
import Strings from "../../../utils/Strings";
import {useGlobal} from "../../../utils/Store";
import Endpoints from "../../../utils/Endpoints";


const items = [
    {
        href: `/${Strings.DASHBOARD_URL}/home`,
        icon: HomeIcon,
        title: 'Home'
    },
    {
        href: `/${Strings.DASHBOARD_URL}/history`,
        icon: TruckIcon,
        title: 'History'
    },
    {
        href: `/${Strings.DASHBOARD_URL}/corporate`,
        icon: CorporateIcon,
        title: 'Corporate'
    },
    {
        href: `/${Strings.DASHBOARD_URL}/billing`,
        icon: BillingIcon,
        title: 'Billing'
    },
    {
        href: `/${Strings.DASHBOARD_URL}/settings`,
        icon: SettingsIcon,
        title: 'Settings'
    },
];

const useStyles = makeStyles(() => ({
    mobileDrawer: {
        width: 256
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        height: 'calc(100% - 64px)'
    },
    avatar: {
        cursor: 'pointer',
        width: 64,
        height: 64
    }
}));

const NavBar = ({onMobileClose, openMobile}) => {
    const classes = useStyles();
    const location = useLocation();
    const [globalState, globalActions] = useGlobal();

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const content = (
        <Box
            height="100%"
            display="flex"
            flexDirection="column"
        >
            <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                p={2}
            >
                <Avatar
                    className={classes.avatar}
                    component={RouterLink}
                    src={Endpoints.SERVER_HOST + Endpoints.FILE_URL + globalState.currentUser.photo}
                    to={`/${Strings.DASHBOARD_URL}/account`}
                />

                <Box mt={2}
                     alignItems="center"
                     display="flex"
                     flexDirection="column">
                    <Typography
                        className={classes.name}
                        color="textPrimary"
                        variant="h5"
                    >
                        {globalState.currentUser.name}
                    </Typography>
                    <Typography
                        color="textSecondary"
                        variant="body2"
                    >
                        {globalState.currentUser.phone}
                    </Typography>
                </Box>
            </Box>
            <Divider/>
            <Box p={2}>
                <List>
                    {items.map((item) => (
                        <NavItem
                            href={item.href}
                            key={item.title}
                            title={item.title}
                            icon={item.icon}
                        />
                    ))}
                </List>
            </Box>
            <Box flexGrow={1}/>
            <Box
                p={2}
                m={2}
                bgcolor="background.dark"
            >
                <Typography
                    align="center"
                    gutterBottom
                    variant="h4"
                >
                    Need help?
                </Typography>
                <Typography
                    align="center"
                    variant="body2"
                >
                    Call +254 755 560 185 or +254 714 074 465 anytime for assitance.
                </Typography>
            </Box>
        </Box>
    );

    return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    classes={{paper: classes.mobileDrawer}}
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer
                    anchor="left"
                    classes={{paper: classes.desktopDrawer}}
                    open
                    variant="persistent"
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
    );
};

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

NavBar.defaultProps = {
    onMobileClose: () => {
    },
    openMobile: false
};

export default NavBar;
