import React from 'react';
import {Box, Button, Card, CardActions, CardContent, Divider, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import NumberFormat from 'react-number-format';
import {useNavigate} from "react-router-dom";
import Strings from "../utils/Strings";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 13,
        marginBottom: 10
    },
    pos: {
        marginBottom: 12,
    },
});


const SubAccountItem = ({item}) => {
    const classes = useStyles();
    const bull = <span className={classes.bullet}>•</span>;

    let navigate = useNavigate()

    return (
        <Box mb={3}>
            <Card className={classes.root} elevation={5}>
                <CardContent>
                    <Typography variant="h4" gutterBottom>
                        {item.email}
                    </Typography>

                    <>
                        <Divider/>
                        <Box mt={2} mb={1}>
                            <Typography variant={"body1"} color="textSecondary">
                                This month's bill as of today with {item.this_months_requests} requests
                            </Typography>
                            <NumberFormat value={item.this_month_string}
                                          displayType={'text'}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          thousandSeparator={true}
                                          prefix={'KES '}
                                          renderText={value => <Typography variant="h4"
                                                                           gutterBottom>{value}</Typography>}/>
                        </Box>
                    </>

                    <Box mb={2}>
                        <Divider/>
                    </Box>
                    <Typography variant={"h6"} color="textSecondary">
                        {item.active === 1 ? "ACTIVE" : "INACTIVE"} {bull} {item.accepted === 1 ? "INVITATION ACCEPTED" : "INVITATION IS PENDING"}
                    </Typography>


                </CardContent>
                <CardActions>
                    <Button size="medium" color="primary"
                            onClick={e => navigate(`/${Strings.DASHBOARD_URL}/sub-account/${item.ID}`, {state: {item: item}})}>See
                        Details</Button>

                </CardActions>
            </Card>
        </Box>
    );
};

export default SubAccountItem;
