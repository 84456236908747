import React, {useEffect, useState} from "react";


const GetCurrentLocation = props => {

    useEffect(() => {
        if (props.getLoc) {
            getCurrentLocation()
        }
    }, [props.getLoc])

    const getCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                let geolocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };

                let url = "https://maps.googleapis.com/maps/api/geocode/json?";

                let finalUrl = url + "latlng=" + geolocation.lat + "," + geolocation.lng + "&key=AIzaSyDLhm9tRWHJaqFxds-4mnMnsnu31WUK4Hc";

                fetch(finalUrl, {method: 'GET'}).then(res => res.json()).then(res => {

                    if (res.status === "OK") {
                        if (res.results.length > 0) {
                            let address = res.results[0].formatted_address;
                            props.locationFound({
                                address: address,
                                lat: geolocation.lat,
                                lng: geolocation.lng
                            })
                        }
                    }
                })

            });
        }
    }

    return (
        <div>

        </div>
    )
};

export default GetCurrentLocation;
