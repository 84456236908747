import {postData} from "../utils/Server";
import Endpoints from "../utils/Endpoints";
import {onServiceDone, parseResponse} from "./Utils";

const fetchRequests = ({
                           status = "Ongoing",
                           businessID = "0",
                           departmentID = "0",
                           childAccountID = "0",
                           done = onServiceDone
                       }) => {
    let data = new FormData();
    data.append("status", status)
    data.append("business_id", businessID)
    data.append("department_id", departmentID)
    data.append("child_account_id", childAccountID)
    parseResponse(postData(Endpoints.LIST_REQUESTS, data), done)
}

const getRequest = ({
                        id = "",
                        done = onServiceDone
                    }) => {
    let data = new FormData();
    data.append("code", id)
    parseResponse(postData(Endpoints.FIND_REQUEST, data), done)
}

const cancelRequest = ({
                           id = "",
                           reason = "",
                           done = onServiceDone
                       }) => {

    if (!(!!reason)) {
        done(null, "Cancellation reason is required")
        return
    }

    let data = new FormData();
    data.append("id", id)
    data.append("reason", reason)
    parseResponse(postData(Endpoints.CANCEL_REQUEST, data), done)
}

const rateRequest = ({
                         id = "",
                         review = "",
                         rating = "",
                         done = onServiceDone
                     }) => {

    if (!(!!rating)) {
        done(null, "Ratings is required")
        return
    }

    let data = new FormData();
    data.append("id", id)
    data.append("rating", rating)
    data.append("review", review)

    parseResponse(postData(Endpoints.RATE_REQUEST, data), done)
}

const receiveGoods = ({
                          file = "",
                          code = "",
                          done = onServiceDone
                      }) => {

    if (!(!!code)) {
        done(null, "Code is required")
        return
    }

    let data = new FormData();
    data.append("file", file)
    data.append("code", code)

    parseResponse(postData(Endpoints.RECEIVE_GOODS, data), done)
}

const sendRequest = ({
                         pick_up_lat = "",
                         pick_up_lng = "",
                         pick_up_location = "",
                         drop_offs = [],
                         is_return = 0,
                         is_scheduled = 0,
                         promo_code = "",
                         done = onServiceDone
                     }) => {


    let data = {
        request_id: 0,
        pick_up_lat: pick_up_lat,
        pick_up_lng: pick_up_lng,
        pick_up_location: pick_up_location,
        is_return: is_return,
        is_scheduled: is_scheduled,
        drop_offs: drop_offs,
        promo_code: promo_code,
    }

    parseResponse(postData(Endpoints.NEW_REQUEST, JSON.stringify([data])), done)
}

const confirmRequest = ({
                            request = {},
                            done = onServiceDone
                        }) => {

    let data = {request_id: request.ID, ...request}

    parseResponse(postData(Endpoints.CONFIRM_REQUEST, JSON.stringify([data])), done)
}

export default {fetchRequests, getRequest, cancelRequest, rateRequest, receiveGoods, sendRequest, confirmRequest}