import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {AppBar, Box, Card, makeStyles, Tab, Tabs, Typography} from '@material-ui/core';
import {TabContext} from "@material-ui/lab";
import RequestFragment from "../../components/RequestFragment";
import BusinessOverviewFragment from "../../components/BusinessOverviewFragment";
import DepartmentFragment from "../../components/DepartmentFragment";
import InvoiceFragment from "../../components/InvoiceFragment";
import DepartmentOverviewFragment from "../../components/DepartmentOverviewFragment";
import ChildAccountsFragment from "../../components/ChildAccountsFragment";
import TabPanel from "../../components/TabPanel";
import ChildAccountOverviewFragment from "../../components/ChildAccountOverviewFragment";
import SubAccountOverviewFragment from "../../components/SubAccountOverviewFragment";


const useStyles = makeStyles((theme) => ({
    root: {},
    avatar: {
        marginRight: theme.spacing(2)
    }
}));

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}


const TabSubAccountDetails = ({className, item, ...rest}) => {
    const classes = useStyles();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    useEffect(() => {

    }, [])

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <PerfectScrollbar>
                <Box>
                    <TabContext value={value}>

                        <AppBar position="static" color="default">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                            >
                                <Tab label="Details" {...a11yProps(0)}/>
                                <Tab label="Requests" {...a11yProps(1)}/>
                            </Tabs>
                        </AppBar>

                        <TabPanel value={value} index={0}>
                            <SubAccountOverviewFragment item={item}/>
                        </TabPanel>

                        <TabPanel value={value} index={1}>
                            <RequestFragment
                                status={"business"}
                                childAccountID={item.ID}/>
                        </TabPanel>
                    </TabContext>
                </Box>
            </PerfectScrollbar>
        </Card>
    );
};

TabSubAccountDetails.propTypes = {
    className: PropTypes.string,
};

export default TabSubAccountDetails;
