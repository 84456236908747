import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    AppBar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Chip,
    Divider,
    makeStyles,
    Tab,
    Tabs,
    Typography
} from '@material-ui/core';
import {TabContext} from "@material-ui/lab";
import TabPanel from "../../components/TabPanel";
import Colors from "../../utils/Colors";
import NumberFormat from "react-number-format";
import {LocalShippingOutlined, LocalShippingRounded} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    avatar: {
        marginRight: theme.spacing(2)
    }
}));


function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}


const TabDeliveryOptions = ({className, item, selectedOptions, ...rest}) => {
    const classes = useStyles();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    useEffect(() => {
        console.log(item)
    }, [])

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <PerfectScrollbar>
                <TabContext value={value}>

                    <AppBar position="static" color="default">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                        >
                            {item.request_types.map((a, b) => <Tab key={a.ID} label={a.name} {...a11yProps(b)}/>)}
                        </Tabs>
                    </AppBar>

                    {item.request_types.map((a, b) =>
                        <TabPanel value={value} index={b}>
                            <Box mt={1}>
                                <Typography variant="body1" style={{color: Colors.grey_700}} gutterBottom>
                                    {a.description}
                                </Typography>
                            </Box>
                            <Divider/>

                            {a.means.map((means, i) => {

                                if (item.means === "" && b === 0 && i === 0 && means.actual_price > item.base_price) {
                                    selectedOptions(a, means)
                                }

                                return <Box mt={2.5} key={means.ID}>
                                    <Card elevation={8}>
                                        <CardContent>
                                            <Box mt={1}>
                                                <Typography variant="h4" gutterBottom>
                                                    {means.name}
                                                </Typography>
                                                <Typography variant="body2" style={{color: Colors.grey_600}}
                                                            gutterBottom>
                                                    {means.description}
                                                </Typography>
                                            </Box>

                                            <Box mt={1.5}>
                                                {means.initial_price < means.actual_price &&
                                                <NumberFormat value={means.initial_price}
                                                              displayType={'text'}
                                                              decimalScale={2}
                                                              fixedDecimalScale={true}
                                                              thousandSeparator={true}
                                                              prefix={'KES '}
                                                              renderText={value => <Typography variant="h5"
                                                                                               style={{
                                                                                                   textDecoration: 'line-through',
                                                                                                   color: Colors.grey_600
                                                                                               }}
                                                                                               gutterBottom>{value}</Typography>}/>
                                                }


                                                <NumberFormat value={means.actual_price}
                                                              displayType={'text'}
                                                              decimalScale={2}
                                                              fixedDecimalScale={true}
                                                              thousandSeparator={true}
                                                              prefix={'KES '}
                                                              renderText={value => <Typography variant="h3"
                                                                                               gutterBottom>{value}</Typography>}/>
                                            </Box>

                                        </CardContent>
                                        <CardActions>
                                            {item.means === means.name && item.delivery_type === a.name ?
                                                <>
                                                    <Chip
                                                        size={"medium"}
                                                        label="Ready for delivery"
                                                        clickable
                                                        color="secondary"
                                                        onClick={() => selectedOptions("", "")}
                                                        icon={<LocalShippingRounded color={"primary"}/>}
                                                    />
                                                </> : <>
                                                    {means.actual_price > item.base_price ?
                                                        <Button
                                                            size={"medium"}
                                                            color="secondary"
                                                            onClick={e => {
                                                                selectedOptions(a, means)
                                                            }}>
                                                            SELECT
                                                        </Button> : <Button
                                                            disabled
                                                            size={"medium"}
                                                            color="primary"
                                                            onClick={e => selectedOptions(a, means)}>
                                                            UNAVAILABLE
                                                        </Button>
                                                    }
                                                </>
                                            }


                                        </CardActions>
                                    </Card>
                                </Box>
                            })}

                        </TabPanel>
                    )}

                </TabContext>
            </PerfectScrollbar>
        </Card>
    );
};

TabDeliveryOptions.propTypes = {
    className: PropTypes.string,
};

export default TabDeliveryOptions;
