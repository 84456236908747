import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField
} from '@material-ui/core';
import {useLocation, useNavigate} from "react-router-dom";
import 'react-vertical-timeline-component/style.min.css';
import PageContainer from "../../components/PageContainer";
import {makeStyles} from "@material-ui/styles";
import {useGlobal} from "../../utils/Store";
import Strings from "../../utils/Strings";
import TabDeliveryOptions from "./TabDeliveryOptions";
import {ERROR, Notify} from "../../utils/Notify";
import RequestsService from "../../services/RequestsService";
import ProgressView from "../../components/ProgressView";
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import CorporateService from "../../services/CorporateService";
import moment from "moment";
import PaymentService from "../../services/PaymentService";
import PayInitiator from "../../components/PayInitiator";
import {clearItem, retrieveItem} from "../../utils/Storage";

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: 200
    },
});


const ConfirmDelivery = () => {
        const navigate = useNavigate();
        const classes = useStyles();
        const {state} = useLocation()

        const [globalState, globalActions] = useGlobal();
        const [isLoading, setIsLoading] = useState(false)
        const [request, setRequest] = useState({})
        const [dropOffs, setDropOffs] = useState([])
        const [billingAccounts, setBillingAccounts] = useState([])
        const [selectedDate, setSelectedDate] = useState(new Date());
        const [selectedTime, setSelectedTime] = useState(new Date());
        const [isPaying, setIsPaying] = useState(false)
        const [res, setRes] = useState({})


        useEffect(() => {
            let currentRequest = retrieveItem('current_request');

            if (currentRequest === null) {
                navigate(`/${Strings.DASHBOARD_URL}`)
                return;
            }

            if (state !== undefined && state !== null) {
                state.request.pick_up_date = moment(selectedDate).format('YYYY-MM-DD')
                state.request.pick_up_time = moment(selectedTime).format('HH:mm')
                state.request.child_account_id = 0
                state.request.payment_method = "CASH"
                state.request.is_scheduled = 0
                state.request.pick_up_type = "Instant"

                setRequest(state.request)
                setDropOffs(state.drop_offs)

                loadBillingAccounts()
                return
            }

            navigate(`/${Strings.DASHBOARD_URL}/request`)
        }, [])


        const resendRequest = (newRequest = request) => {
            setIsLoading(true)
            newRequest.drop_offs = dropOffs
            RequestsService.sendRequest({
                ...newRequest,
                done: (res, err) => {
                    setIsLoading(false)

                    if (err === null && res !== null) {
                        if (res.success === 1) {

                            let previousMeans = newRequest.means
                            let previousDeliveryType = newRequest.delivery_type

                            let item = res.data[0]
                            if (item.promo_code_message !== "") {
                                Notify(item.promo_code_message, ERROR)
                                item.promo_code = ""
                            }

                            if (previousMeans !== "") {
                                item.means = previousMeans
                            }

                            if (previousDeliveryType !== "") {
                                item.delivery_type = previousDeliveryType
                            }

                            setRequest(item)
                        } else {
                            Notify(res.message, ERROR)
                        }

                    } else {
                        Notify(err, ERROR)
                    }
                }
            })
        }

        const applyPromo = () => {
            if (!(!!request.promo_code)) {
                Notify('Please enter a promo code', ERROR)
                return
            }

            resendRequest(request)
        }

        const loadBillingAccounts = () => {


            setIsLoading(true)
            CorporateService.fetchChildAccountsForUser({
                done: (data, err) => {
                    setIsLoading(false)
                    if (err === null && data !== null) {
                        if (data.success === 1 && data.data.length > 0) {
                            setBillingAccounts(data.data || [])
                        }
                    }
                }
            })
        }


        const confirmRequest = (newRequest = request) => {
            if (!(!!request.means) || !(!!(request.delivery_type))) {
                Notify('Please select a delivery option', ERROR)
                return
            }

            if (!(!!request.payment_method)) {
                Notify('Please select a payment method', ERROR)
                return
            }

            if (request.is_scheduled === 1) {
                if (!(!!request.pick_up_date) || !(!!(request.pick_up_time))) {
                    Notify('Please select a delivery date', ERROR)
                    return
                }
            }

            setIsLoading(true)
            newRequest.drop_offs = dropOffs
            RequestsService.confirmRequest({
                request: newRequest,
                done: (res, err) => {
                    setIsLoading(false)

                    if (err === null && res !== null) {
                        if (res.success === 1) {
                            let item = res.data[0]
                            clearItem('current_request')

                            if (item.payment_method === "OTHER") {
                                ///proceed to payment
                                setIsLoading(true)
                                makePayment(item)
                            } else {
                                navigate(`/${Strings.DASHBOARD_URL}/request/${item.request_code}`)
                            }

                        } else {
                            Notify(res.message, ERROR)
                        }

                    } else {
                        Notify(err, ERROR)
                    }
                }
            })
        }

        const makePayment = (item) => {
            setIsLoading(true)
            PaymentService.initiatePayment({
                account_type: "request",
                account_number: item.request_code,
                done: (res, err) => {
                    if (err === null && res !== null) {
                        if (res.success === 1) {
                            res.redirect_page = `request/${item.ID}`
                            setRes(res)
                            setIsPaying(true)
                        } else {
                            setIsLoading(false)
                            Notify(res.message, ERROR)
                        }
                    } else {
                        setIsLoading(false)
                        Notify(err, ERROR)
                    }
                }
            })
        }

        return (
            <PageContainer title={"Pick a delivery option"} toolBarTitle={"Pick a delivery option"}>
                {Object.keys(request).length > 0 &&
                <Box mt={1}>
                    <Grid
                        container
                        direction="row"
                        justify="space-around"
                    >
                        <Grid item md={6} sm={12} xs>
                            <Box m={1}>
                                <TabDeliveryOptions item={request} selectedOptions={(requestType, means) => {
                                    request.delivery_type = requestType.name
                                    request.means = means.name
                                    request.fee = means.actual_price
                                    setRequest({...request})
                                }}/>
                            </Box>
                        </Grid>

                        <Grid item md={6} sm={12} xs>
                            <Box m={1}>
                                <Card elevation={5}>
                                    <CardContent>
                                        <Box>
                                            {/*<RouteMap*/}
                                            {/*    request={request}*/}
                                            {/*    containerElement={<div style={{height: 250}}/>}*/}
                                            {/*    mapElement={<div style={{height: `100%`}}/>}/>*/}
                                        </Box>

                                        <Box>
                                            <TextField
                                                fullWidth
                                                label="Got a promo code?"
                                                onChange={e => {
                                                    request.promo_code = e.target.value
                                                    setRequest({...request})
                                                }}
                                                value={request.promo_code}
                                                variant="outlined"
                                            />
                                            <Button
                                                size={"medium"}
                                                color="secondary"
                                                onClick={applyPromo}>
                                                APPLY PROMO
                                            </Button>
                                        </Box>


                                        <Box mt={3}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={request.is_return === 1}
                                                        onChange={e => {
                                                            request.is_return = e.target.checked ? 1 : 0
                                                            setRequest({...request})
                                                            resendRequest(request)
                                                        }}
                                                        name="checkedB"
                                                        color="primary"
                                                    />
                                                }
                                                label="Return to pick up point after last drop off. Useful for quick errands. Additional charges will apply"
                                            />
                                        </Box>

                                        <Box mt={3}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={request.is_scheduled === 1}
                                                        onChange={e => {
                                                            request.is_scheduled = e.target.checked ? 1 : 0
                                                            request.pick_up_type = e.target.checked ? "Scheduled" : "Instant"
                                                            setRequest({...request})
                                                        }}
                                                        name="checkedB"
                                                        color="primary"
                                                    />
                                                }
                                                label="Schedule pick up for a later time"
                                            />
                                        </Box>

                                        {request.is_scheduled === 1 &&
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Grid container justify="space-around">
                                                <KeyboardDatePicker
                                                    margin="normal"
                                                    id="date-picker-dialog"
                                                    label="Pick up date"
                                                    format="MM/dd/yyyy"
                                                    value={selectedDate}
                                                    onChange={date => {
                                                        setSelectedDate(date)
                                                        request.pick_up_date = moment(date).format('YYYY-MM-DD')
                                                        setRequest({...request})
                                                    }}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                />
                                                <KeyboardTimePicker
                                                    margin="normal"
                                                    id="time-picker"
                                                    label="Pick up time"
                                                    value={selectedTime}
                                                    onChange={date => {
                                                        setSelectedTime(date)
                                                        alert(moment(date).format('HH:mm'))
                                                        request.pick_up_time = moment(date).format('HH:mm')
                                                        setRequest({...request})
                                                    }}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change time',
                                                    }}
                                                />
                                            </Grid>
                                        </MuiPickersUtilsProvider>
                                        }

                                        <Box mt={3}>
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend">Select a billing account</FormLabel>
                                                <Box mt={1} mr={2}>
                                                    <RadioGroup aria-label="billingAccount" name="billingAccount"
                                                                value={parseInt(request.child_account_id)}
                                                                onChange={e => {
                                                                    request.child_account_id = parseInt(e.target.value)
                                                                    if (parseInt(e.target.value) > 0) {
                                                                        request.payment_method = "INVOICE"
                                                                    } else {
                                                                        request.payment_method = "CASH"
                                                                    }
                                                                    setRequest({...request})
                                                                }}>

                                                        <FormControlLabel value={0} control={<Radio/>}
                                                                          label={`${globalState.currentUser.email} - Personal`}/>

                                                        {billingAccounts.map((a, b) =>
                                                            <FormControlLabel key={a.ID} value={a.ID} control={<Radio/>}
                                                                              label={`${a.email} - ${a.department_details.name}, ${a.business_details.name}`}/>
                                                        )}

                                                    </RadioGroup>
                                                </Box>
                                            </FormControl>
                                        </Box>

                                        <Box mt={3}>
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend">Select a payment method</FormLabel>

                                                <Box mt={1} pr={2}>
                                                    <RadioGroup aria-label="paymentMethods" name="paymentMethods"
                                                                value={request.payment_method}
                                                                onChange={e => {
                                                                    request.payment_method = e.target.value
                                                                    setRequest({...request})
                                                                }}>
                                                        {request.child_account_id > 0 ?
                                                            <FormControlLabel value={"INVOICE"} control={<Radio/>}
                                                                              label={`INVOICE - Request will be invoiced to your selected corporate account`}/> :

                                                            <>
                                                                <FormControlLabel value={"CASH"} control={<Radio/>}
                                                                                  label={`CASH - Give cash to the rider`}/>
                                                                <FormControlLabel value={"OTHER"} control={<Radio/>}
                                                                                  label={`MPESA / Credit / Debit Card`}/>
                                                            </>
                                                        }

                                                    </RadioGroup>
                                                </Box>
                                            </FormControl>
                                        </Box>

                                        <Box mt={3}>
                                            <Button
                                                color="primary"
                                                size="large"
                                                variant="contained"
                                                onClick={() => confirmRequest()}
                                            >
                                                {request.payment_method !== "OTHER" ? "Confirm Request" : "Confirm & Make payment"}
                                            </Button>
                                            {isPaying &&
                                            <PayInitiator
                                                btn={"View Request"}
                                                redirectPage={res.redirect_page}
                                                res={res}
                                                onDone={e => {
                                                    setIsPaying(false)
                                                }}/>
                                            }
                                        </Box>


                                    </CardContent>
                                </Card>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                }

                <ProgressView loading={isLoading} showBackDrop={true}/>


            </PageContainer>
        );
    }
;

export default ConfirmDelivery;
