import React, {useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {AppBar, Box, Card, makeStyles, Tab, Tabs, Typography} from '@material-ui/core';
import {TabContext} from "@material-ui/lab";
import RequestFragment from "../../../components/RequestFragment";
import TabPanel from "../../../components/TabPanel";

const useStyles = makeStyles((theme) => ({
    root: {},
    avatar: {
        marginRight: theme.spacing(2)
    }
}));


function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const TabView = ({className, ...rest}) => {
    const classes = useStyles();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <PerfectScrollbar>
                <Box>
                    <TabContext value={value}>

                        <AppBar position="static" color="default">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                            >
                                <Tab label="Ongoing" {...a11yProps(0)}/>
                                <Tab label="Scheduled" {...a11yProps(1)}/>
                                <Tab label="Completed" {...a11yProps(2)}/>
                                <Tab label="Cancelled" {...a11yProps(3)}/>
                            </Tabs>
                        </AppBar>
                        <TabPanel value={value} index={0}>
                            <RequestFragment status={"Ongoing"}/>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <RequestFragment status={"Scheduled"}/>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <RequestFragment status={"Completed"}/>
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <RequestFragment status={"Cancelled"}/>
                        </TabPanel>
                    </TabContext>
                </Box>
            </PerfectScrollbar>
        </Card>
    );
};

TabView.propTypes = {
    className: PropTypes.string,
};

export default TabView;
