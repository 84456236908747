import React, {useEffect, useState} from 'react';
import {Box, Button, Card, CardContent, TextField} from '@material-ui/core';
import {useNavigate} from "react-router-dom";
import 'react-vertical-timeline-component/style.min.css';
import PageContainer from "../../components/PageContainer";
import CorporateService from "../../services/CorporateService";
import {ERROR, Notify, SUCCESS} from "../../utils/Notify";
import ProgressView from "../../components/ProgressView";


const NewBillingAccount = () => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [code, setCode] = useState('')


    useEffect(() => {

    }, [])


    const add = () => {
        setIsLoading(true)
        CorporateService.acceptInvitation({
            email: email,
            code: code,
            done: (res, err) => {
                setIsLoading(false)
                if (err === null && res !== null) {
                    if (res.success === 1) {
                        Notify(res.data, SUCCESS)
                        navigate(-1)
                    } else {
                        Notify(res.message, ERROR)
                    }

                } else {
                    Notify(err, ERROR)
                }
            }
        })
    }


    return (
        <PageContainer title={"Add a billing account"} toolBarTitle={"Add a billing account"}>
            <Box mt={3}>
                <Card elevation={5}>
                    <CardContent>
                        <Box>
                            <TextField
                                fullWidth
                                label="Email address"
                                onChange={e => setEmail(e.target.value)}
                                value={email}
                                variant="outlined"
                            />
                        </Box>


                        <Box mt={3}>
                            <TextField
                                fullWidth
                                label="Invitation code"
                                onChange={e => setCode(e.target.value)}
                                value={code}
                                variant="outlined"
                            />
                        </Box>


                        <Box my={2}>
                            {isLoading ?
                                <ProgressView/>
                                : <Button
                                    color="primary"
                                    size="large"
                                    variant="contained"
                                    onClick={add}
                                >
                                    Accept invitation
                                </Button>}
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </PageContainer>
    );
};

export default NewBillingAccount;
