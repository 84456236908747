import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {Box, Button, Card, CardContent, CardHeader, Divider, makeStyles, TextField} from '@material-ui/core';
import {ERROR, Notify, SUCCESS} from "../../utils/Notify";
import AuthService from "../../services/AuthService";
import {useGlobal} from "../../utils/Store";
import {handleSaveUser} from "../../utils/Auth";
import ProgressView from "../../components/ProgressView";

const useStyles = makeStyles(({
    root: {}
}));

const ProfileEdit = ({className, ...rest}) => {
    const classes = useStyles();

    const [globalState, globalActions] = useGlobal();
    const [loading, setIsLoading] = useState(false)
    const [values, setValues] = useState({
        email: '',
        fullName: ''
    });

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    useEffect(() => {
        let newValues = values
        newValues.email = globalState.currentUser.email
        newValues.fullName = globalState.currentUser.name
        setValues({...newValues})

    }, [globalState.currentUser])


    const updateProfile = () => {
        setIsLoading(true)
        AuthService.updateProfile({
            email: values.email,
            name: values.fullName,
            done: (res, err) => {
                setIsLoading(false)
                if (err === null && res !== null) {
                    if (res.success === 1) {
                        let user = globalState.currentUser
                        user.email = values.email
                        user.name = values.fullName
                        handleSaveUser(globalActions, user)

                        Notify("Profile successfully updated", SUCCESS)
                    } else {
                        Notify(res.message, ERROR)
                    }

                } else {
                    Notify(err, ERROR)
                }
            }
        })
    }

    return (
        <form
            className={clsx(classes.root, className)}
            {...rest}
        >
            <Card>
                <CardHeader
                    subheader="Update your name and email"
                    title="Personal details"
                />
                <Divider/>
                <CardContent>
                    <TextField
                        fullWidth
                        label="Full name"
                        margin="normal"
                        name="fullName"
                        onChange={handleChange}
                        type="text"
                        value={values.fullName}
                        variant="outlined"
                    />
                    <TextField
                        fullWidth
                        label="Email"
                        margin="normal"
                        name="email"
                        onChange={handleChange}
                        type="text"
                        value={values.email}
                        variant="outlined"
                    />
                </CardContent>
                <Divider/>
                <Box
                    display="flex"
                    justifyContent="flex-start"
                    p={2}
                >
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={updateProfile}
                    >
                        Update
                    </Button>
                </Box>
            </Card>

            <ProgressView loading={loading} showBackDrop={true}/>
        </form>
    );
};

ProfileEdit.propTypes = {
    className: PropTypes.string
};

export default ProfileEdit;
