import {postData} from "../utils/Server";
import Endpoints from "../utils/Endpoints";
import {onServiceDone, onServiceError, parseResponse} from "./Utils";

const checkUser = ({phone = "", done = onServiceDone}) => {
    if (phone.length < 10) {
        done(null, "Check your phone input")
        return
    }

    let data = new FormData();
    data.append("phone", phone)
    parseResponse(postData(Endpoints.CHECK_USER, data), done)
}

const verifyUser = ({
                        phone = "",
                        code = "",
                        done = onServiceDone
                    }) => {
    if (phone.length < 10) {
        done(null, "Check your phone input")
        return
    }

    let data = new FormData();
    data.append("phone", phone)
    data.append("code", code)
    parseResponse(postData(Endpoints.VERIFY_PHONE, data), done)
}

const updateProfile = ({
                           email = "",
                           name = "",
                           done = onServiceDone
                       }) => {
    if (!(!!email)) {
        done(null, "Email address is required")
        return
    }

    if (!(!!name)) {
        done(null, "Name is required")
        return
    }

    let data = new FormData();
    data.append("email", email)
    data.append("name", name)
    parseResponse(postData(Endpoints.UPDATE_PROFILE, data), done)
}

const registerUser = ({
                          email = "",
                          name = "",
                          phone = "",
                          done = onServiceDone
                      }) => {
    if (!(!!email)) {
        done(null, "Email address is required")
        return
    }

    if (!(!!name)) {
        done(null, "Name is required")
        return
    }

    if (!(!!phone)) {
        done(null, "Phone is required")
        return
    }

    let data = new FormData();
    data.append("email", email)
    data.append("name", name)
    data.append("phone", phone)

    parseResponse(postData(Endpoints.REGISTER, data), done)
}

const updatePhoto = ({
                         file = "",
                         done = onServiceDone
                     }) => {
    if (!(!!file)) {
        done(null, "File is required")
        return
    }

    let data = new FormData();
    data.append("file", file)
    parseResponse(postData(Endpoints.UPDATE_PHOTO, data), done)
}

export default {checkUser, verifyUser, registerUser, updateProfile, updatePhoto}