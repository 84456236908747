import React from 'react';
import {Container, makeStyles} from "@material-ui/core";
import Page from "./Page";
import {changePageTitle} from "../utils/Common";
import DefaultToolbar from "./DefaultToolbar";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const PageContainer = ({
                           title,
                           showBackButton = true,
                           showToolBar = true,
                           toolBarTitle = title, children
                       }) => {
    const classes = useStyles();

    return (
        <Page
            className={classes.root}
            title={changePageTitle(title)}
        >
            <Container fixed>
                {showToolBar &&
                <DefaultToolbar title={toolBarTitle} showBackButton={showBackButton}/>
                }
                {children}
            </Container>
        </Page>
    );
};

export default PageContainer;
