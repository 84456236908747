import React, {useEffect, useState} from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {
    Box,
    Button,
    Container,
    Grid,
    Link,
    TextField,
    Typography,
    makeStyles, CardContent, Card, Divider
} from '@material-ui/core';
import FacebookIcon from 'src/icons/Facebook';
import GoogleIcon from 'src/icons/Google';
import Page from 'src/components/Page';
import {changePageTitle} from "../../utils/Common";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import {postData} from "../../utils/Server";
import Endpoints from "../../utils/Endpoints";
import {ERROR, Notify} from "../../utils/Notify";
import {useGlobal} from "../../utils/Store";
import ProgressView from "../../components/ProgressView";
import Strings from "../../utils/Strings";
import {handleUserAccess} from "../../utils/Auth";
import AuthService from "../../services/AuthService";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const VerifyView = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    const [code, setCode] = useState('')
    const [phone, setPhone] = useState('')
    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false);

    const logIn = (e) => {
        e.preventDefault();

        if (!(!!phone)) {
            Notify("No phone number selected")
            navigate('/login')
            return
        }

        setIsLoading(true)
        AuthService.verifyUser(
            {
                phone, code, done: (res, err) => {
                    setIsLoading(false);
                    if (err === null) {
                        if (res.success === 1) {
                            handleUserAccess(res, globalActions, false);
                            navigate('/dashboard/home')
                        } else {
                            Notify(res.message, ERROR)
                        }
                    } else {
                        Notify(err, ERROR)
                    }
                }
            })
    }

    useEffect(() => {
        setPhone(globalState.currentPhone)
    }, [])

    return (
        <Page
            className={classes.root}
            title={changePageTitle('Verify')}
        >
            <Box
                display="flex"
                flexDirection="column"
                height="100%"
                justifyContent="center"
            >
                <Container maxWidth="sm">
                    <Card>
                        <CardContent>
                            <Box mb={1}>
                                <Typography
                                    color="textPrimary"
                                    variant="h3"
                                >
                                    Verify your phone number
                                </Typography>
                            </Box>

                            <Box mb={3}>
                                <Typography
                                    color="textSecondary"
                                    variant="body1"
                                >
                                    We've sent a verification code to your phone number. Please enter it below
                                </Typography>
                            </Box>

                            <TextField
                                fullWidth
                                label="Code"
                                onChange={e => setCode(e.target.value)}
                                value={code}
                                variant="outlined"
                            />
                        </CardContent>
                        <Divider/>
                        <Box
                            display="flex"
                            justifyContent="flex-start"
                            p={2}
                        >
                            {isLoading ?
                                <ProgressView showBackDrop={true} loading={isLoading}/>
                                : <Button
                                    color="primary"
                                    size="large"
                                    variant="contained"
                                    onClick={logIn}
                                >
                                    Proceed
                                </Button>}

                            <Button
                                size="large"
                                onClick={() => navigate("/")}
                            >
                                CHANGE PHONE NUMBER
                            </Button>
                        </Box>
                    </Card>
                </Container>
            </Box>
        </Page>
    );
};

export default VerifyView;
