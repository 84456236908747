import React, {useEffect, useState} from 'react';
import {Box, Typography} from "@material-ui/core";
import ProgressView from "./ProgressView";
import CorporateService from "../services/CorporateService";
import DepartmentItem from "./DepartmentItem";

const DepartmentFragment = ({item, status}) => {

    const [isLoading, setIsLoading] = useState(false)
    const [list, setList] = useState([])

    useEffect(() => {
        load()
    }, [])

    const load = () => {
        setIsLoading(true)
        CorporateService.fetchDepartments({
            businessID: item.ID,
            done: (data, err) => {
                setIsLoading(false)
                if (err === null && data !== null) {
                    setList(data.data || [])
                }
            }
        })
    }

    return (
        <Box>
            {isLoading &&
            <Box style={{textAlign: 'center'}} p={10}>
                <ProgressView/>
            </Box>
            }

            {list.map((item, index) => <DepartmentItem key={item.ID} item={item}/>)}

            {list.length === 0 && !isLoading &&
            <Box p={10}>
                <Typography color="textSecondary" gutterBottom variant={"h4"}>
                    No departments found
                </Typography>

                <Typography color="textSecondary" gutterBottom variant={"body1"}>
                    Add departments for your business here for better organisation.
                </Typography>

                <Typography color="textSecondary" gutterBottom variant={"body1"}>
                    Each department will be billed and invoiced separately
                </Typography>

            </Box>

            }
        </Box>
    )
};


export default DepartmentFragment;
