import React from 'react';


export default {
    STORAGE_KEY: 'PNDWEBAPPKey',
    APP_ID: 'PNDWEBAPP-Web-App',
    CONNECTION_ERROR_MESSAGE: 'We encountered an error. We are working to fix it',
    NO_EVENT_MESSAGE: 'No event available at the moment. A stable internet connection is required at least for the first time.',
    FAILED_REGISTRATION: 'Failed to register. Please report or try again later',
    GOOGLE_WEB_CLIENT: '202716085440-q66jjv90kq9lq9d1nojhqrm6he7l337t.apps.googleusercontent.com',
    FACEBOOK_ID: '827789124368635',
    DASHBOARD_URL:"dashboard"
};