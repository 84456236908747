import React, {useEffect, useState} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {makeStyles} from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import {useGlobal} from "../../utils/Store";
import {retrieveItem} from "../../utils/Storage";
import {ERROR, Notify} from "../../utils/Notify";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 64,
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 256
        }
    },
    contentContainer: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden'
    },
    content: {
        flex: '1 1 auto',
        height: '100%',
        overflow: 'auto'
    }
}));

const DashboardLayout = () => {
    const classes = useStyles();
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);
    const [globalState, globalActions] = useGlobal();
    const navigate = useNavigate();
    const location = useLocation();

    const checkUser = () => {
        let user = retrieveItem('user');
        if (user != null) {
            user = JSON.parse(user);
            globalActions.setUserLoggedIn(true);
            globalActions.setCurrentUser(user);
        } else {
            //Notify("Please log in again", ERROR)
            navigate("/")
        }
        globalActions.setUserCheckComplete(true)
    };

    useEffect(() => {
        checkUser()
    }, [globalState.userLoggedIn])

    return (
        <div className={classes.root}>
            <TopBar onMobileNavOpen={() => setMobileNavOpen(true)}/>
            <NavBar
                onMobileClose={() => setMobileNavOpen(false)}
                openMobile={isMobileNavOpen}
            />
            <div className={classes.wrapper}>
                <div className={classes.contentContainer}>
                    <div className={classes.content}>
                        <Outlet/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardLayout;
