import {postData} from "../utils/Server";
import Endpoints from "../utils/Endpoints";
import {onServiceDone, parseResponse} from "./Utils";

const fetchBusinesses = ({
                             done = onServiceDone
                         }) => {
    let data = new FormData();
    parseResponse(postData(Endpoints.LIST_BUSINESSES, data), done)
}

const getBusiness = ({
                         id = "",
                         done = onServiceDone
                     }) => {
    let data = new FormData();
    data.append("id", id)
    parseResponse(postData(Endpoints.FIND_BUSINESS(id), data), done)
}


const updateBusinessStatus = ({
                                  id = "",
                                  active = 0,
                                  done = onServiceDone
                              }) => {
    let data = new FormData();
    data.append("id", id)
    data.append("active", active)
    parseResponse(postData(Endpoints.UPDATE_BUSINESS_STATUS, data), done)
}

const fetchDepartments = ({
                              businessID = 0,
                              done = onServiceDone
                          }) => {
    let data = new FormData();
    data.append("business_id", businessID)
    parseResponse(postData(Endpoints.LIST_DEPARTMENTS, data), done)
}

const getDepartment = ({
                           id = "",
                           done = onServiceDone
                       }) => {
    let data = new FormData();
    data.append("id", id)
    parseResponse(postData(Endpoints.FIND_DEPARTMENT(id), data), done)
}

const updateDepartmentStatus = ({
                                    id = "",
                                    active = 0,
                                    done = onServiceDone
                                }) => {
    let data = new FormData();
    data.append("id", id)
    data.append("active", active)
    parseResponse(postData(Endpoints.UPDATE_DEPARTMENT_STATUS, data), done)
}

const fetchInvoices = ({
                           status = "business",
                           businessID = 0,
                           departmentID = 0,
                           done = onServiceDone
                       }) => {
    let data = new FormData();
    data.append("status", status)
    data.append("business_id", businessID)
    data.append("department_id", departmentID)
    parseResponse(postData(Endpoints.LIST_INVOICES, data), done)
}

const fetchChildAccounts = ({
                                departmentID = 0,
                                done = onServiceDone
                            }) => {
    let data = new FormData();
    data.append("department_id", departmentID)
    parseResponse(postData(Endpoints.LIST_DEPARTMENT_CHILD_ACCOUNTS, data), done)
}

const fetchChildAccountsForUser = ({
                                       done = onServiceDone
                                   }) => {
    let data = new FormData();
    parseResponse(postData(Endpoints.LIST_USER_CHILD_ACCOUNTS, data), done)
}

const getChildAccount = ({
                             id = "",
                             done = onServiceDone
                         }) => {
    let data = new FormData();
    data.append("id", id)
    parseResponse(postData(Endpoints.FIND_CHILD_ACCOUNT(id), data), done)
}

const updateChildAccountStatus = ({
                                      id = "",
                                      departmentID = 0,
                                      active = 0,
                                      done = onServiceDone
                                  }) => {
    let data = new FormData();
    data.append("id", id)
    data.append("department_id", departmentID)
    data.append("active", active)
    parseResponse(postData(Endpoints.UPDATE_CHILD_ACCOUNT_STATUS, data), done)
}

const resendInvitation = ({
                              id = "",
                              done = onServiceDone
                          }) => {
    let data = new FormData();
    data.append("id", id)
    parseResponse(postData(Endpoints.RESEND_INVITATION, data), done)
}

const acceptInvitation = ({
                              email = "",
                              code = "",
                              done = onServiceDone
                          }) => {

    if (!(!!email)) {
        done(null, "Email is required")
        return
    }

    if (!(!!code)) {
        done(null, "Code is required")
        return
    }

    let data = new FormData();
    data.append("email", email)
    data.append("code", code)
    parseResponse(postData(Endpoints.ACCEPT_INVITATION, data), done)
}


const rejectInvitation = ({
                              id = "",
                              done = onServiceDone
                          }) => {
    let data = new FormData();
    data.append("id", id)
    parseResponse(postData(Endpoints.REJECT_INVITATION, data), done)
}

const addChildAccount = ({
                             email = "",
                             departmentID = "",
                             businessID = "",
                             done = onServiceDone
                         }) => {

    if (!(!!email)) {
        done(null, "Check your email")
        return
    }

    if (!(!!departmentID)) {
        done(null, "Department is required")
        return
    }

    if (!(!!businessID)) {
        done(null, "Business is required")
        return
    }

    let data = new FormData();
    data.append("email", email)
    data.append("department_id", departmentID)
    data.append("business_id", businessID)

    parseResponse(postData(Endpoints.NEW_CHILD_ACCOUNT, data), done)
}

const addDepartment = ({
                           name = "",
                           contact_email = "",
                           contact_phone = "",
                           invoice_email = "",
                           budget = "",
                           businessID = "",
                           done = onServiceDone
                       }) => {

    if (!(!!name)) {
        done(null, "Name is required")
        return
    }

    if (!(!!contact_email)) {
        done(null, "Contact email is required")
        return
    }

    if (!(!!contact_phone)) {
        done(null, "Contact phone is required")
        return
    }

    if (!(!!invoice_email)) {
        done(null, "Invoice email is required")
        return
    }

    if (!(!!budget)) {
        done(null, "Budget is required")
        return
    }

    let data = new FormData();
    data.append("name", name)
    data.append("contact_email", contact_email)
    data.append("contact_phone", contact_phone)
    data.append("invoice_email", invoice_email)
    data.append("budget", budget)
    data.append("business_id", businessID)

    parseResponse(postData(Endpoints.NEW_DEPARTMENT, data), done)
}

const addBusiness = ({
                         name = "",
                         category = "",
                         business_type = "",
                         kra_pin = "",
                         reg_no = "",
                         contact_phone = "",
                         contact_email = "",
                         postal_address = "",
                         physical_address = "",
                         country = "",
                         document = "",
                         done = onServiceDone
                     }) => {

    if (!(!!name)) {
        done(null, "Name is required")
        return
    }

    if (!(!!contact_email)) {
        done(null, "Contact email is required")
        return
    }

    if (!(!!contact_phone)) {
        done(null, "Contact phone is required")
        return
    }

    if (!(!!kra_pin)) {
        done(null, "KRA P.I.N is required")
        return
    }

    if (!(!!reg_no)) {
        done(null, "Registration number is required")
        return
    }

    if (!(!!document)) {
        done(null, "Supporting document is required")
        return
    }

    let data = new FormData();
    data.append("name", name)
    data.append("contact_email", contact_email)
    data.append("contact_phone", contact_phone)
    data.append("category", category)
    data.append("business_type", business_type)
    data.append("kra_pin", kra_pin)
    data.append("reg_no", reg_no)
    data.append("postal_address", postal_address)
    data.append("physical_address", physical_address)
    data.append("country", country)
    data.append("document", document)

    parseResponse(postData(Endpoints.NEW_BUSINESS, data), done)
}

export default {
    fetchBusinesses,
    getBusiness,
    updateBusinessStatus,
    fetchDepartments,
    getDepartment,
    updateDepartmentStatus,
    fetchInvoices,
    fetchChildAccounts,
    fetchChildAccountsForUser,
    updateChildAccountStatus,
    getChildAccount,
    addChildAccount,
    resendInvitation,
    acceptInvitation,
    rejectInvitation,
    addDepartment,
    addBusiness
}