import React, {useEffect} from 'react';
import {IconButton} from "@material-ui/core";
import {ArrowBack} from "@material-ui/icons";
import Colors from "../utils/Colors";
import {Navigate, useNavigate} from "react-router-dom";

const BackButton = (props) => {
    let navigate = useNavigate()

    const goBack = () => navigate(-1)

    return (
        <IconButton color={Colors.black} aria-label="back" component="span" onClick={goBack}>
            <ArrowBack/>
        </IconButton>
    );
};

export default BackButton;
