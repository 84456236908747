import {storeItem} from "./Storage";

export const handleUserAccess = (res, globalActions, redToAccount) => {
    let user = res.data.user;
    let token = res.data.token;

    storeItem('user', JSON.stringify(user));
    storeItem('token', token);
    globalActions.setCurrentUser({});
    globalActions.setCurrentUser(user);
    globalActions.setUserLoggedIn(true);

    if (redToAccount) {
        window.location.assign(process.env.PUBLIC_URL + "/dashboard")
    }
};

export const handleSaveUser = (globalActions, user) => {
    storeItem('user', JSON.stringify(user));
    globalActions.setCurrentUser({});
    globalActions.setCurrentUser(user);
};