import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    Divider,
    Grid,
    Icon,
    Paper,
    TextField,
    Typography
} from '@material-ui/core';
import PageContainer from "../../../components/PageContainer";
import RequestsService from "../../../services/RequestsService";
import {useNavigate, useParams} from "react-router-dom";
import NumberFormat from "react-number-format";
import ProgressView from "../../../components/ProgressView";
import Colors from "../../../utils/Colors";
import {ERROR, Notify, SUCCESS} from "../../../utils/Notify";
import {Rating} from "@material-ui/lab";
import {getFileUrl} from "../../../utils/Server";
import 'react-vertical-timeline-component/style.min.css';
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import {
    Truck as TruckIcon,
} from 'react-feather';

const RequestDetails = () => {
    const {id} = useParams()

    const navigate = useNavigate();
    const [item, setItem] = useState('')
    const [loading, setLoading] = useState(false)
    const [isCancelling, setIsCancelling] = useState(false)
    const [isRating, setIsRating] = useState(false)
    const [reason, setReason] = useState('')
    const [rating, setRating] = useState(5)

    useEffect(() => {
        loadDetails()
    }, [])

    const loadDetails = () => {
        setLoading(true)
        RequestsService.getRequest({
            id: id, done: (res, err) => {
                setLoading(false)
                if (err === null && res !== null) {
                    setItem(res.data)
                } else {
                    Notify(err, ERROR)
                }
            }
        })
    }


    const cancelRequest = e => {
        setLoading(true)
        RequestsService.cancelRequest({
            id: id,
            reason: reason,
            done: (res, err) => {
                setLoading(false)
                if (err === null && res !== null) {
                    if (res.success === 1) {
                        Notify(res.data, SUCCESS)
                        navigate('/dashboard')
                    } else {
                        Notify(res.message, ERROR)
                    }

                } else {
                    Notify(err, ERROR)
                }
            }
        })
    }


    const rateRequest = e => {
        setLoading(true)
        RequestsService.rateRequest({
            id: id,
            review: reason,
            rating: rating,
            done: (res, err) => {
                setLoading(false)
                if (err === null && res !== null) {
                    if (res.success === 1) {
                        Notify(res.data, SUCCESS)
                        setIsRating(false)
                        setReason('')
                        loadDetails()
                    } else {
                        Notify(res.message, ERROR)
                    }
                } else {
                    Notify(err, ERROR)
                }
            }
        })
    }


    const DetailSection = props => <Box mt={2.5}>
        {!props.isRating &&
        <Typography style={{
            fontSize: 17,
            fontWeight: 600,
            color: Colors.grey_900
        }}>{props.title === "" ? "-" : props.title}</Typography>
        }

        {props.isRating &&
        <Box>
            <Rating name="read-only" value={props.title} readOnly/>
        </Box>
        }
        <Typography style={{fontSize: 14, color: Colors.grey_700}}>{props.des === "" ? "-" : props.des}</Typography>
        <Box mt={1}>
            <Divider/>
        </Box>
    </Box>

    const TrackingItem = ({item}) => <Box mt={2.5}>
        <Paper variant={"outlined"}>
            <Box p={1}>
                <Typography style={{
                    fontSize: 16,
                    fontWeight: 500,
                    color: Colors.green_500
                }}>{item.date_added_string}</Typography>
                <Typography style={{fontSize: 14, color: Colors.grey_700}}>{item.text}</Typography>
            </Box>
        </Paper>
    </Box>

    return (

        <PageContainer title={loading ? "" : `Request #${item.request_code}`}>
            <Box mt={3}>
                <Card elevation={5}>
                    <CardContent>
                        {loading &&
                        <Box style={{textAlign: 'center'}} p={3}>
                            <ProgressView/>
                        </Box>
                        }


                        {!loading && item !== '' && item.status !== "DRAFT" &&
                        <Box>
                            <Box alignItems="center"
                                 display="flex"
                                 flexDirection="column">
                                <NumberFormat value={item.fee}
                                              displayType={'text'}
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              thousandSeparator={true}
                                              prefix={'KES '}
                                              renderText={value => <Typography variant="h1"
                                                                               align={"center"}>{value}</Typography>}/>

                                <Typography variant={"caption"} align={"center"}>Total Cost</Typography>

                                {(item.status === "PENDING" || item.status === "ACCEPTED BY RIDER") &&
                                <Box mt={2}>
                                    {!isCancelling &&
                                    <Button
                                        style={{backgroundColor: Colors.red_400, color: Colors.white}}
                                        variant="contained"
                                        onClick={() => setIsCancelling(true)}
                                    >
                                        Cancel request
                                    </Button>
                                    }

                                </Box>
                                }


                                {(item.status === "ACCEPTED BY RIDER" ||
                                    item.status === "ARRIVED AT PICK UP" ||
                                    item.status === "DELIVERY IN PROGRESS" ||
                                    item.status === "DELIVERY IN PROGRESS(R2)" ||
                                    item.status === "DELIVERY IN PROGRESS(R3)" ||
                                    item.status === "DELIVERY IN PROGRESS(CC)" ||
                                    item.status === "COMPLETED" && !isRating) &&
                                <Box mt={3}>
                                    <Paper style={{width: 300, padding: 10}} elevation={8}>
                                        <Box
                                            alignItems="center"
                                            display="flex"
                                            flexDirection="column"
                                            p={2}
                                        >
                                            <Avatar
                                                style={{height: 100, width: 100}}
                                                src={getFileUrl(item.rider_details.photo)}
                                            />
                                            <Box mt={1}>
                                                <Typography variant={"body1"}>{item.rider_details.name}</Typography>
                                            </Box>

                                            <Box mt={1}>
                                                <Typography variant={"h3"}>{item.rider_details.reg_no}</Typography>
                                            </Box>

                                            {item.status === "COMPLETED" && !isRating &&
                                            <Box mt={3}>
                                                <Button
                                                    color={"primary"}
                                                    variant="contained"
                                                    onClick={() => setIsRating(true)}
                                                >
                                                    Rate service
                                                </Button>
                                            </Box>
                                            }

                                        </Box>
                                    </Paper>
                                </Box>
                                }
                            </Box>


                            {isCancelling &&
                            <Box mb={3} mt={2}>
                                <Paper variant={"outlined"}>
                                    <Box p={5}>
                                        <Typography
                                            variant="body1"
                                        >
                                            We look forward to serving you better. Please share your cancellation reason
                                            below.
                                        </Typography>

                                        <Box mb={2} mt={1}>
                                            <TextField
                                                fullWidth
                                                multiline
                                                label="Cancellation reason"
                                                onChange={e => setReason(e.target.value)}
                                                value={reason}
                                                variant="outlined"
                                            />
                                        </Box>

                                        <Box my={2}>
                                            <Button
                                                style={{marginRight: 20}}
                                                color="primary"
                                                size="large"
                                                variant="contained"
                                                onClick={cancelRequest}
                                            >
                                                Submit
                                            </Button>

                                            <Button
                                                size="large"
                                                variant="outlined"
                                                onClick={e => setIsCancelling(false)}
                                            >
                                                Back
                                            </Button>

                                        </Box>
                                    </Box>
                                </Paper>
                            </Box>
                            }

                            {isRating &&
                            <Box mb={3} mt={2}>
                                <Paper variant={"outlined"}>
                                    <Box p={5}>
                                        <Typography
                                            variant="body1"
                                        >
                                            We appreciate your feedback in helping to make our services better.
                                        </Typography>

                                        <Box mb={2} mt={2}>
                                            <Rating
                                                name="simple-controlled"
                                                value={rating}
                                                onChange={(event, newValue) => {
                                                    setRating(newValue);
                                                }}
                                                size={"large"}
                                            />
                                        </Box>

                                        <Box mb={2} mt={2}>
                                            <TextField
                                                fullWidth
                                                multiline
                                                label="Write your feedback here"
                                                onChange={e => setReason(e.target.value)}
                                                value={reason}
                                                variant="outlined"
                                            />
                                        </Box>

                                        <Box my={2}>
                                            <Button
                                                style={{marginRight: 20}}
                                                color="primary"
                                                size="large"
                                                variant="contained"
                                                onClick={rateRequest}
                                            >
                                                Submit
                                            </Button>

                                            <Button
                                                size="large"
                                                variant="outlined"
                                                onClick={e => setIsRating(false)}
                                            >
                                                Back
                                            </Button>

                                        </Box>
                                    </Box>
                                </Paper>
                            </Box>
                            }

                            {!isCancelling && !isRating &&
                            <Box mt={3}>
                                <VerticalTimeline>
                                    <VerticalTimelineElement
                                        className="vertical-timeline-element--work"
                                        contentStyle={{background: 'rgb(33, 150, 243)', color: '#fff'}}
                                        contentArrowStyle={{borderRight: '7px solid  rgb(33, 150, 243)'}}
                                        date=""
                                        iconStyle={{background: 'rgb(33, 150, 243)', color: '#fff'}}
                                        icon={<TruckIcon size="20"/>}
                                    >
                                        <Typography className="vertical-timeline-element-title" variant={"h6"}>Pick up
                                            point</Typography>
                                        <Typography variant={"h4"}
                                                    className="vertical-timeline-element-subtitle">{item.pick_up_location}</Typography>
                                        <Typography variant={"body1"}>

                                        </Typography>
                                    </VerticalTimelineElement>

                                    {item.drop_offs.map((a, b) => <VerticalTimelineElement
                                        className="vertical-timeline-element--work"
                                        date=""
                                        iconStyle={{background: 'rgb(33, 150, 243)', color: '#fff'}}
                                        icon={<TruckIcon/>}
                                    >
                                        <Typography variant={"h5"}
                                                    className="vertical-timeline-element-subtitle">{a.address}</Typography>
                                        <Typography variant={"body1"} gutterBottom color={"textSecondary"}>
                                            Phone : {a.phone}
                                        </Typography>
                                        <Typography variant={"body1"} gutterBottom color={"textSecondary"}>
                                            Notes : {a.notes}
                                        </Typography>
                                        <Typography variant={"body1"} gutterBottom color={"textSecondary"}>
                                            Status : {a.status}
                                        </Typography>
                                    </VerticalTimelineElement>)}

                                </VerticalTimeline>


                                <Grid
                                    container
                                    direction="row"
                                >
                                    <Grid item md={6} sm={12}>
                                        <Box p={1}>
                                            <DetailSection
                                                title={item.is_scheduled === 0 ? item.pick_up_type : `${item.pick_up_type}, on ${item.pick_up_date_time_string}`}
                                                des={"Pick up type"}/>
                                            <DetailSection title={item.pick_up_location} des={"Pick up location"}/>

                                            <DetailSection title={item.progress_status} des={"Status"}/>
                                            <DetailSection title={`${item.distance_m} M (${item.distance_km} KM)`}
                                                           des={"Total distance"}/>
                                            <DetailSection title={item.means} des={"Means"}/>
                                            <DetailSection title={item.delivery_type} des={"Delivery type"}/>
                                            <DetailSection
                                                title={item.transaction_id === "" ? item.payment_method : `${item.rave_payment_method} - ${item.transaction_id}`}
                                                des={"Payment details"}/>

                                            {item.status === "COMPLETED" &&
                                            <DetailSection title={item.customer_rating} des={"Customer rating"}
                                                           isRating={true}/>
                                            }

                                            <DetailSection
                                                title={item.status === "COMPLETED" ? item.customer_feedback : "-"}
                                                des={"Customer review"}/>
                                            <DetailSection title={item.status === "COMPLETED" ? item.rider_review : "-"}
                                                           des={"Rider review"}/>

                                            <DetailSection title={item.corporate_id > 0 ? "YES" : "NO"}
                                                           des={"via Corporate"}/>
                                            <DetailSection
                                                title={item.merchant_reference === "" ? "-" : item.merchant_reference}
                                                des={"Corporate reference"}/>

                                            {item.corporate_id > 0 ?
                                                <Box>
                                                    <DetailSection title={item.child_account_details.email}
                                                                   des={"Billed to"}/>
                                                    <DetailSection title={item.department_details.name}
                                                                   des={"Department"}/>
                                                    <DetailSection title={item.business_details.name}
                                                                   des={"Business"}/>
                                                </Box>
                                                : <DetailSection title={item.user_details.email}
                                                                 des={"Billed to"}/>}

                                            <DetailSection title={item.request_code}
                                                           des={"Request code"}/>

                                            <DetailSection title={item.date_string}
                                                           des={"Requested on"}/>
                                        </Box>
                                    </Grid>

                                    <Grid item md={6} sm={12}>
                                        <Box p={2}>
                                            {item.tracking.reverse().map((t, i) => <TrackingItem item={t}/>)}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            }


                        </Box>
                        }
                    </CardContent>
                </Card>

            </Box>
        </PageContainer>
    );
};

export default RequestDetails;
