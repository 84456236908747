import React, {useEffect, useState} from 'react';
import {Box, Card, CardContent} from '@material-ui/core';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import 'react-vertical-timeline-component/style.min.css';
import PageContainer from "../../components/PageContainer";
import ProgressView from "../../components/ProgressView";
import CorporateService from "../../services/CorporateService";
import {ERROR, Notify} from "../../utils/Notify";
import TabBusinessDetails from "./TabBusinessDetails";
import BusinessDetailsToolbar from "./BusinessDetailsToolbar";


const BusinessDetails = () => {
    const navigate = useNavigate();
    const {id} = useParams()
    const {state} = useLocation()

    const [item, setItem] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        loadDetails()
    }, [])

    const loadDetails = () => {
        if (state !== undefined && state !== null) {
            setItem(state.item)
            return
        }

        setLoading(true)
        CorporateService.getBusiness({
            id: id, done: (res, err) => {
                setLoading(false)
                if (err === null && res !== null) {
                    if (res.success === 1) {
                        setItem(res.data)
                    } else {
                        Notify(res.message, ERROR)
                    }
                } else {
                    Notify(err, ERROR)
                }
            }
        })

    }


    return (

        <PageContainer title={item.name} showToolBar={false}>
            <BusinessDetailsToolbar item={item}/>
            <Box mt={3}>

                {loading &&
                <Card elevation={5}>
                    <CardContent>
                        <Box style={{textAlign: 'center'}} p={3}>
                            <ProgressView/>
                        </Box>
                    </CardContent>
                </Card>
                }

                {!loading && item !== '' &&
                <TabBusinessDetails item={item}/>
                }

            </Box>
        </PageContainer>
    );
};

export default BusinessDetails;
