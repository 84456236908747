import React, {useEffect, useState} from 'react';
import {Box, Button, Card, CardActions, CardContent, Divider, TextField, Typography} from '@material-ui/core';
import {useNavigate} from "react-router-dom";
import 'react-vertical-timeline-component/style.min.css';
import PageContainer from "../../components/PageContainer";
import ProgressView from "../../components/ProgressView";
import {makeStyles} from "@material-ui/styles";
import Colors from "../../utils/Colors";
import AddressInput from "../../components/AddressInput";
import DeleteIcon from '@material-ui/icons/Delete';
import {ERROR, Notify, SUCCESS} from "../../utils/Notify";
import RequestsService from "../../services/RequestsService";
import {useGlobal} from "../../utils/Store";
import Strings from "../../utils/Strings";
import {retrieveItem, storeItem} from "../../utils/Storage";
import GetCurrentLocation from "./GetCurrentLocation";

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: 200
    },
});
const addressObject = {
    lat: 0.00,
    lng: 0.00,
    address: "",
    phone: "",
    notes: ""
}

const RequestDelivery = () => {
    const navigate = useNavigate();
    const classes = useStyles();


    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false)
    const [pickUpAddress, setPickAddress] = useState('')
    const [pickUpLat, setPickUpLat] = useState('')
    const [pickUpLng, setPickUpLng] = useState('')
    const [usingCurrentLoc, setUsingCurrentLoc] = useState(false)

    const [dropOffAddresses, setDropOffAddresses] = useState([addressObject])

    useEffect(() => {
        let currentRequest = retrieveItem('current_request');

        if (currentRequest !== null) {
            currentRequest = JSON.parse(currentRequest)
            setPickAddress(currentRequest.pick_up_location)
            setPickUpLng(currentRequest.pick_up_lng)
            setPickUpLat(currentRequest.pick_up_lat)
            setDropOffAddresses(currentRequest.drop_offs)
        }else{
            setUsingCurrentLoc(true)
        }

    }, [])


    const sendRequest = () => {
        if (!(!!pickUpAddress)) {
            Notify('Pick up address is required', ERROR)
            return
        }

        let errors = ""

        dropOffAddresses.map((ad, i) => {
            if (!(!!ad.address)) {
                errors = 'Drop off at position #' + (i + 1) + ' is missing address'
                return
            }

            if (!(!!ad.lat) || !(!!(ad.lng))) {
                errors = 'Drop off at address - ' + ad.address + ' is missing complete coordinates'
                return
            }

            if (!(!!ad.phone)) {
                errors = 'Drop off at address - ' + ad.address + ' is missing recipient phone number'
            }
        })

        if (errors !== "") {
            Notify(errors, ERROR)
            return
        }

        setIsLoading(true)

        RequestsService.sendRequest({
            pick_up_lat: pickUpLat,
            pick_up_lng: pickUpLng,
            pick_up_location: pickUpAddress,
            drop_offs: dropOffAddresses,
            done: (res, err) => {
                setIsLoading(false)
                if (err === null && res !== null) {
                    if (res.success === 1) {
                        storeItem('current_request', JSON.stringify({
                            valid: true,
                            pick_up_location: pickUpAddress,
                            pick_up_lat: pickUpLat,
                            pick_up_lng: pickUpLng,
                            drop_offs: dropOffAddresses
                        }))

                        navigate(`/${Strings.DASHBOARD_URL}/confirm`, {
                            state: {
                                request: res.data[0],
                                drop_offs: dropOffAddresses
                            }
                        })
                    } else {
                        Notify(res.message, ERROR)
                    }

                } else {
                    Notify(err, ERROR)
                }
            }
        })


    }

    const addDropOff = () => {
        let addresses = dropOffAddresses
        addresses.push(addressObject)
        setDropOffAddresses([...addresses])
    }

    const removeDropOff = (item, i) => {
        let addresses = dropOffAddresses
        addresses.splice(i, 1);
        setDropOffAddresses([...addresses])
    }

    const updateDropOff = (item, i) => {
        let addresses = dropOffAddresses
        addresses[i] = item
        setDropOffAddresses([...addresses])
    }

    return (
        <PageContainer title={"Request delivery"} toolBarTitle={"Request delivery"}>
            <Box mt={3}>
                <GetCurrentLocation
                    getLoc={usingCurrentLoc}
                    locationFound={e => {
                    setPickAddress(e.address)
                    setPickUpLat(e.lat)
                    setPickUpLng(e.lng)
                }}/>

                <Card elevation={5}>
                    <CardContent>
                        <Box>
                            <Typography color="textSecondary" gutterBottom variant={"h5"}>
                                Pick up address
                            </Typography>
                        </Box>
                        <Box mt={2}>
                            <AddressInput
                                query={pickUpAddress}
                                onAddress={(address, latLng) => {
                                    setPickAddress(address)
                                    setPickUpLat(latLng.lat())
                                    setPickUpLng(latLng.lng())
                                }}
                            />
                        </Box>

                        <Box mt={3}>
                            <Divider/>
                        </Box>

                        <Box mt={2}>
                            <Typography color="textSecondary" gutterBottom variant={"h5"}>
                                Drop off addresses
                            </Typography>

                            <Typography color="textSecondary" gutterBottom variant={"body2"}>
                                *Note that the order of drop offs matters. The last drop off will be considered the
                                final stop.
                            </Typography>

                            {dropOffAddresses.map((item, i) => {

                                return (
                                    <Box mt={2.5} key={i}>
                                        <Card elevation={8}>
                                            <CardContent>
                                                <Box mt={1}>
                                                    <AddressInput
                                                        query={item.address}
                                                        onAddress={(address, latLng) => {
                                                            item.address = address
                                                            item.lat = latLng.lat()
                                                            item.lng = latLng.lng()
                                                            updateDropOff(item, i)
                                                        }}
                                                    />
                                                </Box>
                                                <Box mt={2}>
                                                    <TextField
                                                        fullWidth
                                                        label="Recipient phone number"
                                                        type={"phone"}
                                                        onChange={e => {
                                                            item.phone = e.target.value
                                                            updateDropOff(item, i)
                                                        }}
                                                        value={item.phone}
                                                        variant="outlined"
                                                    />
                                                </Box>
                                                <Box mt={2}>
                                                    <TextField
                                                        multiline={true}
                                                        fullWidth
                                                        label="Additional notes or an order list"
                                                        onChange={e => {
                                                            item.notes = e.target.value
                                                            updateDropOff(item, i)
                                                        }}
                                                        value={item.notes}
                                                        variant="outlined"
                                                    />
                                                </Box>
                                            </CardContent>
                                            {i > 0 &&
                                            <CardActions>
                                                <Button size="small"
                                                        style={{backgroundColor: Colors.white, color: Colors.red_500}}
                                                        startIcon={<DeleteIcon/>}
                                                        onClick={e => removeDropOff(item, i)}>
                                                    REMOVE DROP OFF
                                                </Button>
                                            </CardActions>
                                            }
                                        </Card>
                                    </Box>
                                )
                            })}

                            <Box mt={1}>
                                <Button
                                    size={"medium"}
                                    color="secondary"
                                    onClick={addDropOff}>
                                    ADD ANOTHER DROP OFF
                                </Button>
                            </Box>
                        </Box>

                        <Box mt={3}>
                            <Divider/>
                        </Box>

                        <Box my={2}>
                            <Button
                                color="primary"
                                size="large"
                                variant="contained"
                                onClick={sendRequest}
                            >
                                Send Request
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </Box>

            <ProgressView loading={isLoading} showBackDrop={true}/>
        </PageContainer>
    );
};

export default RequestDelivery;
