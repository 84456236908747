const IS_DEV = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export default {
    SERVER_HOST: IS_DEV ? 'http://localhost:9292' : "https://api.pickndropservices.com",
    WS_HOST: IS_DEV ? 'ws://localhost:5000' : "wss://api.pickndropservices.com",
    API_URL: '/api/v1/',
    FILE_URL: '/files/',
    WS: 'ws',
    CHECK_USER: 'user/check-phone',
    VERIFY_PHONE: 'user/verify-phone',
    REGISTER: 'user/register',
    UPDATE_PROFILE: 'user/update/settings',
    UPDATE_PHOTO: 'user/update/photo',

    LIST_REQUESTS: "request/list-user",
    FIND_REQUEST: "request/find-code",
    CANCEL_REQUEST: 'request/cancel-user',
    RATE_REQUEST: 'request/rate-user',

    LIST_BUSINESSES: "business/list-user",
    FIND_BUSINESS: (id) => "business/find-user?id=" + id,
    UPDATE_BUSINESS_STATUS: 'business/update/status',
    NEW_BUSINESS: "business/new",

    LIST_DEPARTMENTS: "department/list-user",
    FIND_DEPARTMENT: (id) => "department/find-user?id=" + id,
    UPDATE_DEPARTMENT_STATUS: 'department/update/status',
    NEW_DEPARTMENT: "department/new",

    LIST_INVOICES: "invoice/list-user",

    LIST_DEPARTMENT_CHILD_ACCOUNTS: "child-account/list-user",
    FIND_CHILD_ACCOUNT: (id) => "child-account/find-user?id=" + id,
    UPDATE_CHILD_ACCOUNT_STATUS: 'child-account/update/status',
    RESEND_INVITATION: 'child-account/resend-invitation',
    ACCEPT_INVITATION: 'child-account/accept',
    REJECT_INVITATION: 'child-account/reject',
    LIST_USER_CHILD_ACCOUNTS: "child-account/list-for-user",
    NEW_CHILD_ACCOUNT: "child-account/new",

    GET_SITE_SETTINGS: 'settings/list-user',

    INITIATE_PAYMENT: 'pay/initiate',

    RECEIVE_GOODS: 'request/user-receive',
    NEW_REQUEST: 'request/new',
    CONFIRM_REQUEST: 'request/confirm'
};
