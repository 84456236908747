import React from 'react';
import {Box} from "@material-ui/core";
import PropTypes from "prop-types";

const TabPanel = (props) => {
  const {children, value, index, ...other} = props;

    return (
        <Box
            display={value !== index ? "none" : ""}
            role="tabpanel"
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
            p={2}
        >
            {children}
        </Box>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export default TabPanel;
