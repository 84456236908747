import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {Box, Grid, makeStyles, Typography} from '@material-ui/core';
import BackButton from "./BackButton";
import ToolbarTitle from "./ToolbarTitle";

const useStyles = makeStyles((theme) => ({
    root: {},
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    }
}));

const DefaultToolbar = ({className, title, showBackButton = true, ...rest}) => {
    const classes = useStyles();

    return (
        <div
            className={clsx(classes.root, className)}
            {...rest}
        >
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
            >
                <Grid item
                      xs={12}>
                    <Box
                        display="flex"
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        {showBackButton &&
                        <BackButton/>
                        }
                        <ToolbarTitle title={title}/>
                    </Box>

                </Grid>

            </Grid>


            {/*<Box mt={3}>*/}
            {/*    <Card>*/}
            {/*        <CardContent>*/}
            {/*            <Box maxWidth={500}>*/}
            {/*                <TextField*/}
            {/*                    fullWidth*/}
            {/*                    InputProps={{*/}
            {/*                        startAdornment: (*/}
            {/*                            <InputAdornment position="start">*/}
            {/*                                <SvgIcon*/}
            {/*                                    fontSize="small"*/}
            {/*                                    color="action"*/}
            {/*                                >*/}
            {/*                                    <SearchIcon/>*/}
            {/*                                </SvgIcon>*/}
            {/*                            </InputAdornment>*/}
            {/*                        )*/}
            {/*                    }}*/}
            {/*                    placeholder="Search customer"*/}
            {/*                    variant="outlined"*/}
            {/*                />*/}
            {/*            </Box>*/}
            {/*        </CardContent>*/}
            {/*    </Card>*/}
            {/*</Box>*/}
        </div>
    );
};

DefaultToolbar.propTypes = {
    className: PropTypes.string
};

export default DefaultToolbar;
