import React from 'react';
import {Box, Button, Card, CardActions, CardContent, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import NumberFormat from 'react-number-format';
import {useNavigate} from "react-router-dom";
import Strings from "../utils/Strings";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 13,
        marginBottom: 10
    },
    pos: {
        marginBottom: 12,
    },
});

const RequestItem = ({item}) => {
    const classes = useStyles();
    const bull = <span className={classes.bullet}>•</span>;
    let navigate= useNavigate()

    return (
        <Box mb={3}>
            <Card className={classes.root} elevation={5}>
                <CardContent>
                    <Typography className={classes.title} color="textSecondary">
                        Request #{item.request_code}
                    </Typography>
                    <Typography variant="h5">
                        {item.is_scheduled === 0 ? `Pick up at ${item.pick_up_location}` : `Pick up on ${item.pick_up_date_time_string}`}
                    </Typography>
                    <Box mt={2} mb={1}>
                        <NumberFormat value={item.fee}
                                      displayType={'text'}
                                      decimalScale={2}
                                      fixedDecimalScale={true}
                                      thousandSeparator={true}
                                      prefix={'KES '}
                                      renderText={value => <Typography variant="h3">{value}</Typography>}/>
                    </Box>

                    <Typography className={classes.pos} color="textSecondary">
                        {item.delivery_type}{bull}{item.means}
                    </Typography>

                </CardContent>
                <CardActions>
                    <Button size="medium" color="primary"
                            onClick={e => navigate(`/${Strings.DASHBOARD_URL}/request/${item.request_code}`)}>See
                        Details</Button>
                </CardActions>
            </Card>
        </Box>
    );
};

export default RequestItem;
