import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {Box, Button, Grid, makeStyles, Typography} from '@material-ui/core';
import {useNavigate} from "react-router-dom";
import BackButton from "../../components/BackButton";
import Strings from "../../utils/Strings";
import ToolbarTitle from "../../components/ToolbarTitle";


const useStyles = makeStyles((theme) => ({
    root: {},
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    }
}));

const Toolbar = ({className, ...rest}) => {
    const classes = useStyles();
    const navigate = useNavigate()

    return (
        <div
            className={clsx(classes.root, className)}
            {...rest}
        >
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
            >
                <Grid item
                      xs={6}>
                    <Box
                        display="flex"
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <BackButton/>
                        <ToolbarTitle title={"Billing"}/>
                    </Box>


                </Grid>

                <Grid item xs={6}>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => navigate(`/${Strings.DASHBOARD_URL}/billing/new`)}
                        >
                            Add a billing account
                        </Button>
                    </Box>
                </Grid>

            </Grid>

        </div>
    );
};

Toolbar.propTypes = {
    className: PropTypes.string
};

export default Toolbar;
