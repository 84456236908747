import React from 'react';
import {Box} from '@material-ui/core';
import PageContainer from "../../components/PageContainer";
import HomeView from "./HomeView";


const Home = () => {

    return (
        <PageContainer title={"Home"} showToolBar={false} showBackButton={false}>
            <Box mt={3}>
                <HomeView/>
            </Box>
        </PageContainer>
    );
};

export default Home;
