import React from 'react';
import {Box, Button, Container, makeStyles, Typography} from '@material-ui/core';
import Page from 'src/components/Page';
import {useNavigate} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    image: {
        marginTop: 50,
        display: 'inline-block',
        maxWidth: '100%',
        width: 560
    }
}));

const NotFoundView = () => {
    const classes = useStyles();
    const navigate = useNavigate()

    return (
        <Page
            className={classes.root}
            title="404"
        >
            <Box
                display="flex"
                flexDirection="column"
                height="100%"
                justifyContent="center"
            >
                <Container maxWidth="md">
                    <Typography
                        align="center"
                        color="textPrimary"
                        variant="h1"
                    >
                        404: The page you are looking for isn’t here
                    </Typography>
                    <Typography
                        align="center"
                        color="textPrimary"
                        variant="subtitle2"
                    >
                        You can visit our website for more information
                    </Typography>
                    <Box textAlign="center">
                        <img
                            alt="Under development"
                            className={classes.image}
                            src="/static/images/undraw_page_not_found_su7k.svg"
                        />
                    </Box>

                     <Box textAlign="center">
                         <Button
                            color="primary"
                            variant="contained"
                            onClick={() => navigate("/")}
                        >
                            Go Home
                        </Button>
                    </Box>

                </Container>
            </Box>
        </Page>
    );
};

export default NotFoundView;
