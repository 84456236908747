import React, {useEffect, useState} from 'react';
import RequestItem from "./RequestItem";
import {Box, Divider, Typography} from "@material-ui/core";
import ProgressView from "./ProgressView";
import CorporateService from "../services/CorporateService";
import BusinessItem from "./BusinessItem";

const BusinessFragment = ({className, status}) => {

    const [isLoading, setIsLoading] = useState(false)
    const [list, setList] = useState([])

    useEffect(() => {
        load()
    }, [])

    const load = () => {
        setIsLoading(true)
        CorporateService.fetchBusinesses({
            status: status,
            done: (data, err) => {
                setIsLoading(false)
                if (err === null && data !== null) {
                    setList(data.data || [])
                }
            }
        })
    }

    return (
        <Box>
            {isLoading &&
            <Box style={{textAlign: 'center'}} p={10}>
                <ProgressView/>
            </Box>
            }
            <Box p={2}>
                {list.map((item, index) => <BusinessItem key={item.ID} item={item}/>)}
            </Box>

            {list.length === 0 && !isLoading &&
            <Box p={10}>
                <Typography color="textSecondary" gutterBottom variant={"h3"}>
                    Get the best of our services with corporate mode.
                </Typography>

                <Typography color="textSecondary" gutterBottom variant={"body1"}>
                    Integrate Pick&Drop into your daily delivery processes and let's make work easier for you.
                </Typography>

                <Typography color="textSecondary" gutterBottom variant={"body1"}>
                    Enjoy our flexible monthly payment plan with reduced bulk rates for corporates.
                </Typography>

                <Typography color="textSecondary" gutterBottom variant={"body1"}>
                    Add multiple departments and users for your business for easy monitoring and budget allocation.
                </Typography>

                <Typography color="textSecondary" gutterBottom variant={"body1"}>
                    Add your business to get started.
                </Typography>

            </Box>

            }
        </Box>
    )
};


export default BusinessFragment;
