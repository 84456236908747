import Strings from "../utils/Strings";
import {ERROR, Notify} from "../utils/Notify";

export const onServiceDone = (data, err) => {
}

export const onServiceError = (err) => {
    Notify(Strings.CONNECTION_ERROR_MESSAGE, ERROR)
}

export const parseResponse = (res, done) => {
    res.then(res => res.json())
        .then(res => {
            done(res, null)
        })
        .catch((error) => {
            done(null, null)
            onServiceError(error)
        });
}
