import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Box,
    Button,
    Card,
    CardContent,
    TextField,
    InputAdornment,
    SvgIcon,
    makeStyles, Typography, Grid
} from '@material-ui/core';
import {Search as SearchIcon} from 'react-feather';
import BackButton from "../../../components/BackButton";
import {Navigate, useNavigate} from "react-router-dom";
import Strings from "../../../utils/Strings";

const useStyles = makeStyles((theme) => ({
    root: {},
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    }
}));

const Toolbar = ({className, ...rest}) => {
    const classes = useStyles();
    const navigate = useNavigate()

    return (
        <div
            className={clsx(classes.root, className)}
            {...rest}
        >
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
            >
                <Grid item
                      xs={6}>
                    <Box
                        display="flex"
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <BackButton/>
                        <Typography
                            color="textPrimary"
                            variant="h4"
                        >
                            History
                        </Typography>
                    </Box>


                </Grid>

                <Grid item xs={6}>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                    >
                        <Button
                            color="primary"
                            size={"medium"}
                            variant="contained"
                            onClick={() => navigate(`/${Strings.DASHBOARD_URL}/request`)}
                        >
                            Request delivery
                        </Button>
                    </Box>
                </Grid>

            </Grid>


            {/*<Box mt={3}>*/}
            {/*    <Card>*/}
            {/*        <CardContent>*/}
            {/*            <Box maxWidth={500}>*/}
            {/*                <TextField*/}
            {/*                    fullWidth*/}
            {/*                    InputProps={{*/}
            {/*                        startAdornment: (*/}
            {/*                            <InputAdornment position="start">*/}
            {/*                                <SvgIcon*/}
            {/*                                    fontSize="small"*/}
            {/*                                    color="action"*/}
            {/*                                >*/}
            {/*                                    <SearchIcon/>*/}
            {/*                                </SvgIcon>*/}
            {/*                            </InputAdornment>*/}
            {/*                        )*/}
            {/*                    }}*/}
            {/*                    placeholder="Search customer"*/}
            {/*                    variant="outlined"*/}
            {/*                />*/}
            {/*            </Box>*/}
            {/*        </CardContent>*/}
            {/*    </Card>*/}
            {/*</Box>*/}
        </div>
    );
};

Toolbar.propTypes = {
    className: PropTypes.string
};

export default Toolbar;
