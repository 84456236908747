import React from 'react';

const Logo = (props) => {
  return (
    <img
      alt="Logo"
      src="/static/logo.png"
      {...props}
        style={{
          height:30
        }}
    />
  );
};

export default Logo;
