import React, {useEffect, useState} from 'react';
import {Box, Button, Card, CardContent, Container, makeStyles, Typography} from '@material-ui/core';
import {useNavigate, useLocation} from "react-router-dom";
import 'react-vertical-timeline-component/style.min.css';
import PageContainer from "../../components/PageContainer";
import Strings from "../../utils/Strings";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    image: {
        marginTop: 50,
        display: 'inline-block',
        maxWidth: '100%',
        width: 100
    }
}));

const SuccessPage = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const classes = useStyles();

    const [red, setRed] = useState(new URLSearchParams(location.search).get("red"));
    const [btnTitle, setBtn] = useState(new URLSearchParams(location.search).get("btn"));

    useEffect(() => {

    }, [])


    return (
        <PageContainer title={"Payment Successful"} toolBarTitle={"Payment"} showBackButton={false}>
            <Box mt={3}>
                <Card elevation={5}>
                    <CardContent>
                        <Box p={3}
                             alignItems="center"
                             display="flex"
                             flexDirection="column">
                            <Box textAlign="center">
                                <img
                                    alt="Card"
                                    className={classes.image}
                                    src="/static/images/credit-card.png"
                                />
                            </Box>
                            <Box mt={3}>
                                <Typography
                                    align="center"
                                    color="textPrimary"
                                    variant="h3"
                                    gutterBottom
                                >
                                    Payment Successful
                                </Typography>
                            </Box>

                            <Box mt={3}>
                                <Typography
                                    align="center"
                                    color="textPrimary"
                                    variant="body1"
                                >
                                    Your payment has been received and is being processed. You'll be notified once
                                    successful.
                                </Typography>
                            </Box>

                            <Box mt={3}>
                                {red ? <Button size="medium" color={"primary"} variant={"outlined"}
                                               onClick={e => navigate(`/${Strings.DASHBOARD_URL}/${red}`)}>{btnTitle}</Button> :
                                    <Button size="medium" color={"primary"} variant={"outlined"}
                                            onClick={e => navigate(`/${Strings.DASHBOARD_URL}/`)}>Go
                                        Home</Button>}

                            </Box>


                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </PageContainer>
    );
};

export default SuccessPage;
