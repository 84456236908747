import React from 'react';
import {Box} from '@material-ui/core';
import PageContainer from "../../components/PageContainer";
import Toolbar from "./Toolbar";
import ListView from "./ListView";


const Corporate = () => {

    return (
        <PageContainer title={"Corporate"} showToolBar={false}>
            <Toolbar/>
            <Box mt={3}>
                <ListView/>
            </Box>
        </PageContainer>
    );
};

export default Corporate;
