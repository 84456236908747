import React, {useEffect} from 'react';
import {Avatar, Box, Button, Card, Grid, makeStyles, Typography} from '@material-ui/core';
import {Link as RouterLink, useNavigate} from "react-router-dom";
import Strings from "../../utils/Strings";
import {useGlobal} from "../../utils/Store";
import Endpoints from "../../utils/Endpoints";
import {clearItem} from "../../utils/Storage";

const useStyles = makeStyles(() => ({
    avatar: {
        width: 70,
        height: 70
    }
}));

const HomeView = ({className, ...rest}) => {
    const navigate = useNavigate()
    const [globalState, globalActions] = useGlobal();
    const classes = useStyles();

    useEffect(() => {
        clearItem('current_request')
    }, [])

    return (
        <Card
            {...rest}
        >

            <Box p={5} style={{textAlign: 'center'}}>

                <Box m={2}
                     alignItems="center"
                     display="flex"
                     flexDirection="column">
                    <Avatar
                        className={classes.avatar}
                        src={Endpoints.SERVER_HOST + Endpoints.FILE_URL + globalState.currentUser.photo}
                    />
                </Box>


                <Typography color="textPrimary" gutterBottom variant={"h3"}>
                    Hi {globalState.currentUser.name}
                </Typography>

                <Typography color="textSecondary" gutterBottom variant={"h5"}>
                    What service can we offer you today?
                </Typography>

                <Box mt={6}>
                    <Grid
                        container
                        direction="row"
                    >
                        <Grid item xs>
                            <Box m={2}>
                                <img src={"/static/images/request_delivery.png"} style={{height: 50}}/>
                            </Box>
                            <Box m={0.5}>
                                <Button size="medium" color={"primary"} variant={"outlined"}
                                        onClick={e => navigate(`/${Strings.DASHBOARD_URL}/request`)}>Request
                                    Delivery</Button>
                            </Box>
                        </Grid>

                        <Grid item xs>
                            <Box m={2}>
                                <img src={"/static/images/receive.png"} style={{height: 50}}/>
                            </Box>
                            <Box m={0.5}>
                                <Button size="medium" color={"primary"} variant={"outlined"}
                                        onClick={e => navigate(`/${Strings.DASHBOARD_URL}/receive`)}>Receive
                                    Goods</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

            </Box>


        </Card>
    );
};

export default HomeView;
