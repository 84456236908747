import React from 'react';
import {Box, makeStyles} from '@material-ui/core';
import TabView from './TabView';
import Toolbar from './Toolbar';
import PageContainer from "../../../components/PageContainer";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const MyHistory = () => {
    const classes = useStyles();

    return (
        <PageContainer title={"History"} showToolBar={false} toolBarTitle={"History"}>
            <Toolbar/>
            <Box mt={3}>
                <TabView/>
            </Box>
        </PageContainer>
    );
};

export default MyHistory;
