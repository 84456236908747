import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Container, Divider,
    FormControl, Grid,
    InputLabel, MenuItem, Select,
    TextField,
    Typography
} from '@material-ui/core';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import 'react-vertical-timeline-component/style.min.css';
import PageContainer from "../../components/PageContainer";
import CorporateService from "../../services/CorporateService";
import {ERROR, Notify, SUCCESS} from "../../utils/Notify";
import ProgressView from "../../components/ProgressView";
import SiteService from "../../services/SiteService";

const categories = [
    "Arts, crafts, and collectibles.",
    "Baby.",
    "Beauty and fragrances.",
    "Books and magazines.",
    "Business to business.",
    "Clothing, accessories, and shoes.",
    "Computers, accessories, and services.",
    "Education.",
    "Electronics and telecom.",
    "Entertainment and media.",
    "Food retail and service.",
    "Gifts and flowers.",
    "Health and personal care.",
    "Home and garden.",
    "Nonprofit.",
    "Pets and animals.",
    "Religion and spirituality (for profit).",
    "Retail (not elsewhere classified).",
    "Services - other.",
    "Sports and outdoors.",
    "Toys and hobbies.",
    "Travel.",
    "Vehicle sales.",
    "Vehicle service and accessories.",
]

const businessTypes = [
    "Non Profit/Charity/Church",
    "Non Governmental Organisation",
    "Society",
    "Sacco/Cooperative",
    "Government Parastatal",
    "Limited Company",
    "Partnership",
    "Sole Proprietorship",
    "None",
]

const NewBusiness = () => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false)
    const [name, setName] = useState('')
    const [category, setCategory] = useState(categories[0])
    const [businessType, setBusinessType] = useState(businessTypes[0])
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [pin, setPIN] = useState('')
    const [regNo, setRegNo] = useState('')
    const [postalAddress, setPostalAddress] = useState('')
    const [physicalAddress, setPhysicalAddress] = useState('')
    const [document, setDocument] = useState('')

    const [settings, setSettings] = useState('')


    useEffect(() => {
        loadSettings()
    }, [])

    const loadSettings = () => {
        setIsLoading(true)
        SiteService.getSiteSettings({
            done: (res, err) => {
                setIsLoading(false)
                if (err === null && res !== null) {
                    setSettings(res.data[0])
                }
            }
        })
    }


    const add = () => {
        setIsLoading(true)

        CorporateService.addBusiness({
            contact_email: email,
            business_type: businessType,
            contact_phone: phone,
            category: category,
            kra_pin: pin,
            reg_no: regNo,
            postal_address: postalAddress,
            physical_address: physicalAddress,
            country: "Kenya",
            name: name,
            document: document,
            done: (res, err) => {
                setIsLoading(false)
                if (err === null && res !== null) {
                    if (res.success === 1) {
                        Notify(res.data, SUCCESS)
                        navigate('/dashboard/corporate')
                    } else {
                        Notify(res.message, ERROR)
                    }

                } else {
                    Notify(err, ERROR)
                }
            }
        })
    }


    return (
        <PageContainer title={"Add new business"} toolBarTitle={"Add new business"}>
            <Box mt={3}>
                <Card elevation={5}>
                    <CardContent>
                        <Box>
                            <TextField
                                fullWidth
                                label="Business name"
                                onChange={e => setName(e.target.value)}
                                value={name}
                                variant="outlined"
                            />
                        </Box>

                        <Box mt={3}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel>Category</InputLabel>
                                <Select
                                    value={category}
                                    onChange={e => setCategory(e.target.value)}
                                    label="Category"
                                >
                                    {categories.map((a, b) => <MenuItem value={a}>{a}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Box>


                        <Box mt={3}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel>Business Type</InputLabel>
                                <Select
                                    value={businessType}
                                    onChange={e => setBusinessType(e.target.value)}
                                    label="Category"
                                >
                                    {businessTypes.map((a, b) => <MenuItem value={a}>{a}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Box>

                        <Box mt={3}>
                            <TextField
                                fullWidth
                                label="KRA P.I.N"
                                onChange={e => setPIN(e.target.value)}
                                value={pin}
                                variant="outlined"
                            />
                        </Box>

                        <Box mt={3}>
                            <TextField
                                fullWidth
                                label="Business registration number"
                                onChange={e => setRegNo(e.target.value)}
                                value={regNo}
                                variant="outlined"
                            />
                        </Box>

                        <Box mt={3}>
                            <TextField
                                fullWidth
                                label="Contact email address"
                                onChange={e => setEmail(e.target.value)}
                                value={email}
                                variant="outlined"
                            />
                        </Box>

                        <Box mt={3}>
                            <TextField
                                fullWidth
                                label="Contact phone number"
                                onChange={e => setPhone(e.target.value)}
                                value={phone}
                                variant="outlined"
                            />
                        </Box>

                        <Box mt={3}>
                            <TextField
                                fullWidth
                                label="Postal address"
                                onChange={e => setPostalAddress(e.target.value)}
                                value={postalAddress}
                                variant="outlined"
                            />
                        </Box>

                        <Box mt={3}>
                            <TextField
                                fullWidth
                                label="Physical address"
                                onChange={e => setPhysicalAddress(e.target.value)}
                                variant="outlined"
                                value={physicalAddress}
                            />
                        </Box>

                        {/*<Box mt={3}>*/}
                        {/*    <InputLabel>*Upload a supporting document for this business</InputLabel>*/}
                        {/*    <TextField*/}
                        {/*        fullWidth*/}
                        {/*        variant="outlined"*/}
                        {/*        type={"file"}*/}
                        {/*        value={physicalAddress}*/}
                        {/*    />*/}
                        {/*</Box>*/}

                        <Box mt={3}>
                            <InputLabel>Upload a supporting document for this business</InputLabel>
                            <Box mt={1}>
                                <label htmlFor="btn-upload">
                                    <input
                                        onChange={e => setDocument(e.target.files[0])}
                                        type={"file"}
                                        style={{display: 'none'}}
                                        id="btn-upload"
                                    />
                                    <Button
                                        className="btn-choose"
                                        variant="outlined"
                                        component="span">
                                        Choose Document
                                    </Button>
                                </label>
                            </Box>
                            <Box mt={1}>
                                {document &&
                                <Typography color="textSecondary" variant={"h6"} gutterBottom
                                            style={{fontStyle: 'italic'}}>
                                    SELECTED DOCUMENT - {document.name}
                                </Typography>
                                }
                            </Box>

                            <Box mt={3}>
                                <Divider/>
                            </Box>

                        </Box>

                        <Box mt={3}>
                            <Typography color="textSecondary" gutterBottom style={{fontStyle: 'italic'}}>
                                *A non refundable fee of KES {settings.business_fee} is required for all new business
                                registrations
                                for quality assurance.
                            </Typography>
                        </Box>

                        <Box my={2}>
                            {isLoading ?
                                <ProgressView/>
                                : <Button
                                    color="primary"
                                    size="large"
                                    variant="contained"
                                    onClick={add}
                                >
                                    Add
                                </Button>}
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </PageContainer>
    );
};

export default NewBusiness;
