import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, Button, Card, CardContent, Container, Divider, makeStyles, Typography} from '@material-ui/core';
import Page from 'src/components/Page';
import {changePageTitle} from "../../utils/Common";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import {ERROR, Notify} from "../../utils/Notify";
import {useGlobal} from "../../utils/Store";
import ProgressView from "../../components/ProgressView";
import AuthService from "../../services/AuthService";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const LoginView = () => {

    const classes = useStyles();
    const navigate = useNavigate();

    const [phone, setPhone] = useState('')
    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(false);

    const onDone = (res, err) => {
        setIsLoading(false)
        if (err === null) {
            if (res.success === 1) {
                globalActions.setCurrentPhone(res.data.phone)
                navigate('/verify')
            } else {
                globalActions.setCurrentPhone(res.data)
                navigate('/register', {
                    state: {
                        phone: res.message
                    }
                })
            }
        } else {
            Notify(err, ERROR)
        }
    }

    const logIn = (e) => {
        e.preventDefault();
        setIsLoading(true)
        AuthService.checkUser({phone: phone, done: onDone})
    }


    return (
        <Page
            className={classes.root}
            title={changePageTitle('Login')}
        >
            <Box
                display="flex"
                flexDirection="column"
                height="100%"
                justifyContent="center"
            >
                <Container maxWidth="sm">

                    <Card>
                        <CardContent>
                            <Box mb={1}>
                                <Typography
                                    color="textPrimary"
                                    variant="h3"
                                >
                                    Pick&Drop - Consider it done!
                                </Typography>
                            </Box>

                            <Box mb={3}>
                                <Typography
                                    color="textSecondary"
                                    variant="body1"
                                >
                                    We are making it easier to have your goods delivered from anywhere you are to any
                                    point you want.
                                </Typography>
                            </Box>
                            <Box>
                                <PhoneInput
                                    fullWidth
                                    country={'ke'}
                                    placeholder={"Enter your phone number"}
                                    value={phone}
                                    countryCodeEditable={false}
                                    onChange={phone => setPhone(phone)}
                                    inputStyle={{
                                        width: '100%'
                                    }}
                                />
                            </Box>
                        </CardContent>
                        <Divider/>
                        <Box
                            display="flex"
                            justifyContent="flex-start"
                            p={2}
                        >
                            {isLoading ?
                                <ProgressView showBackDrop={true} loading={isLoading}/>
                                : <Button
                                    color="primary"
                                    disabled={false}
                                    size="large"
                                    variant="contained"
                                    onClick={logIn}
                                >
                                    Proceed
                                </Button>}
                        </Box>
                    </Card>


                </Container>
            </Box>
        </Page>
    );
};

export default LoginView;
