import useGlobalHook from "use-global-hook";
import React from "react";

const initialState = {
    currentUser: {},
    userLoggedIn: false,
    accountType: 'tenant',
    userCheckComplete: false,
    currentRequest: {valid: false}
};

export const actions = {
    setCurrentUser: (store, user) => {
        store.setState({currentUser: user});
    },
    setCurrentPhone: (store, item) => {
        store.setState({currentPhone: item});
    },
    setUserLoggedIn: (store, loggedIn) => {
        store.setState({userLoggedIn: loggedIn});
    },
    setUserCheckComplete: (store, complete) => {
        store.setState({userCheckComplete: complete});
    },
    setCurrentRequest: (store, current) => {
        store.setState({currentRequest: current});
    },
};

// eslint-disable-next-line react-hooks/rules-of-hooks
export const useGlobal = useGlobalHook(React, initialState, actions);
