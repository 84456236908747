import React from 'react';
import {Typography} from "@material-ui/core";

const ToolbarTitle = ({title}) => {

    return (
        <Typography
            color="textPrimary"
            variant="h4"
        >
            {title}
        </Typography>
    );
};

export default ToolbarTitle;
