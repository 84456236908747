import React, {useState} from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {AppBar, Avatar, Box, Divider, Hidden, IconButton, makeStyles, Menu, MenuItem, Toolbar} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from 'src/components/Logo';
import Colors from "../../utils/Colors";
import Endpoints from "../../utils/Endpoints";
import {useGlobal} from "../../utils/Store";
import Strings from "../../utils/Strings";
import {clearItem} from "../../utils/Storage";

const useStyles = makeStyles(() => ({
    root: {},
    avatar: {
        width: 40,
        height: 40,
        cursor: 'pointer'
    }
}));

const TopBar = ({
                    className,
                    onMobileNavOpen,
                    ...rest
                }) => {
    const navigate = useNavigate()
    const classes = useStyles();
    const [globalState, globalActions] = useGlobal();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSettings = () => {
        handleClose()
        navigate(`/${Strings.DASHBOARD_URL}/settings`)
    }

    const handleDownloadApp = () => {
        handleClose()
        window.open("https://play.google.com/store/apps/details?id=pickanddrop.android.publicapp")
    }

    const handleLogOut = () => {
        handleClose()
        clearItem('user')
        clearItem('token')

        globalActions.setUserLoggedIn(false)
    }

    return (
        <AppBar
            className={clsx(classes.root, className)}
            elevation={0}
            {...rest}
        >
            <Toolbar style={{backgroundColor: Colors.black}}>
                <Hidden lgUp>
                    <IconButton
                        color="inherit"
                        onClick={onMobileNavOpen}
                    >
                        <MenuIcon/>
                    </IconButton>
                </Hidden>
                <RouterLink to="/">
                    <Logo/>
                </RouterLink>
                <Box flexGrow={1}/>
                <Box>
                    <Avatar
                        className={classes.avatar}
                        onClick={handleClick}
                        src={Endpoints.SERVER_HOST + Endpoints.FILE_URL + globalState.currentUser.photo}
                    />
                </Box>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleSettings}>Edit profile</MenuItem>
                    <MenuItem onClick={handleSettings}>Change photo</MenuItem>
                    <Divider/>
                    <MenuItem onClick={handleDownloadApp}>Download app</MenuItem>
                    <Divider/>
                    <MenuItem onClick={handleLogOut}>Log out</MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
};

TopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func
};

export default TopBar;
