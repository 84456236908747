import React, {useEffect, useState} from 'react';
import RequestsService from "../services/RequestsService";
import RequestItem from "./RequestItem";
import {Box, Typography} from "@material-ui/core";
import ProgressView from "./ProgressView";

const RequestFragment = ({className,
                             status,
                             businessID = 0,
                             departmentID = 0,
                             childAccountID = 0}) => {

    const [isLoading, setIsLoading] = useState(false)
    const [list, setList] = useState([])

    useEffect(() => {
        loadRequests()
    }, [])

    const loadRequests = () => {
        setIsLoading(true)
        RequestsService.fetchRequests({
            businessID:businessID,
            departmentID:departmentID,
            childAccountID:childAccountID,
            status: status,
            done: (data, err) => {
                setIsLoading(false)
                if (err === null && data !== null) {
                    setList(data.data || [])
                }
            }
        })
    }

    return (
        <Box>
            {isLoading &&
            <Box style={{textAlign: 'center'}} p={10}>
                <ProgressView/>
            </Box>
            }
            {list.map((item, index) => <RequestItem key={item.id} item={item}/>)}

            {list.length === 0 && !isLoading &&
            <Box style={{textAlign: 'center'}} p={10}>
                <Typography style={{fontSize: 16}} color="textSecondary" gutterBottom>
                    No requests available at the moment
                </Typography>
            </Box>

            }
        </Box>
    );
};


export default RequestFragment;
