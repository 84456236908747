import React, {useEffect, useState} from 'react';
import {Box, Button, Card, CardContent, Container, TextField} from '@material-ui/core';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import 'react-vertical-timeline-component/style.min.css';
import PageContainer from "../../components/PageContainer";
import CorporateService from "../../services/CorporateService";
import {ERROR, Notify, SUCCESS} from "../../utils/Notify";
import ProgressView from "../../components/ProgressView";


const NewChildAccount = () => {
    const navigate = useNavigate();
    const {state} = useLocation()

    const [data, setID] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [email, setEmail] = useState('')

    useEffect(() => {
        loadDetails()
    }, [])

    const loadDetails = () => {
        if (state !== undefined && state !== null) {
            setID(state)
            return
        }

        navigate('/dashboard/corporate')

    }

    const addAccount = () => {
        setIsLoading(true)

        CorporateService.addChildAccount({
            email: email,
            departmentID: state.department_id,
            businessID: state.business_id,
            done: (res, err) => {
                setIsLoading(false)
                if (err === null && res !== null) {
                    if (res.success === 1) {
                        Notify(res.data, SUCCESS)
                        navigate('/dashboard/department/' + state.department_id)
                    } else {
                        Notify(res.message, ERROR)
                    }

                } else {
                    Notify(err, ERROR)
                }
            }
        })
    }


    return (
        <PageContainer title={"Add new billing account"} toolBarTitle={"Add new billing account"}>
            <Box mt={3}>
                <Card elevation={5}>
                    <CardContent>
                        <Box>
                            <TextField
                                fullWidth
                                label="Email address to invite"
                                onChange={e => setEmail(e.target.value)}
                                value={email}
                                variant="outlined"
                            />

                            <Box my={2}>
                                {isLoading ?
                                    <ProgressView/>
                                    : <Button
                                        color="primary"
                                        disabled={false}
                                        size="large"
                                        variant="contained"
                                        onClick={addAccount}
                                    >
                                        Send invitation
                                    </Button>}


                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </PageContainer>
    );
};

export default NewChildAccount;
