import React, {useEffect, useRef, useState} from "react";
import PlacesAutocomplete from 'react-places-autocomplete';
import {
    geocodeByAddress,
    geocodeByPlaceId,
    getLatLng,
} from 'react-places-autocomplete';
import {ERROR, Notify} from "../utils/Notify";
import Colors from "../utils/Colors";
import {Box, Paper, TextField, Typography} from "@material-ui/core";
import ProgressView from "./ProgressView";

const searchOptions = {
    componentRestrictions: {country: 'ke'},
}

const AddressInput = props => {

    const [query, setQuery] = useState('')

    const handleSelect = address => {
        setQuery(address)
        geocodeByAddress(address)
            .then(results => {
                console.log(results)
                props.onAddress(address, results[0].geometry.location)
            })
            .catch(error => {
                console.log(error)
                Notify("Failed to fetch address", ERROR)
            });
    };

    useEffect(() => {
        if (props.query) {
            setQuery(props.query)
        }
    }, [props.query]);

    const renderFunc = ({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
        <div>
            <TextField
                {...getInputProps({
                    placeholder: 'Type your address here...',
                    className: 'form-control',
                })}
                fullWidth
                label="Type your address here..."
                variant="outlined"
            />
            <Paper variant={"outline"} p={1} elevation={3}>
                {loading && <Box style={{textAlign: 'center'}} p={1}><ProgressView/></Box>}
                {suggestions.map(suggestion => {

                    return (
                        <Box
                            {...getSuggestionItemProps(suggestion)}

                            style={{
                                backgroundColor: suggestion.active ? Colors.grey_400 : Colors.grey_200,
                                fontSize: 14,
                                color: Colors.grey_800,
                                padding: 5,
                                borderBottom: 1,
                                borderBottomColor: Colors.grey_700,
                                cursor: 'pointer'
                            }}
                        >
                            <i className="fas fa-fw fa-location-arrow"></i>
                            <Typography color="textSecondary" gutterBottom variant={"body1"}>
                                {suggestion.description}</Typography>
                        </Box>
                    );
                })}
            </Paper>
        </div>
    )

    return (
        <PlacesAutocomplete
            searchOptions={searchOptions}
            value={query}
            onChange={e => setQuery(e)}
            onSelect={handleSelect}
        >
            {renderFunc}
        </PlacesAutocomplete>
    )
};

export default AddressInput;