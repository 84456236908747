import React, {useEffect} from 'react';
import {closePaymentModal, useFlutterwave} from "flutterwave-react-v3";
import PaymentService from "../services/PaymentService";
import ProgressView from "./ProgressView";
import {Box} from "@material-ui/core";
import Strings from "../utils/Strings";

const PayInitiator = ({res, redirectPage = "payment-success", btn="View", onDone}) => {

    let config = PaymentService.getConfig(res.data)
    const handleFlutterPayment = useFlutterwave(config);

    useEffect(() => {
        handleFlutterPayment({
            callback: (response) => {
                console.log(response);
                closePaymentModal()
                onDone()
                if (response.status === 'successful') {
                    let red = redirectPage !== "payment-success" ? `/${Strings.DASHBOARD_URL}/payment-success?red=${redirectPage}&btn=${btn}` : `/${Strings.DASHBOARD_URL}/${redirectPage}`
                    window.location.assign(red)
                }
            },
            onClose: () => {
                onDone()
            },
        });
    })

    return (
        <Box p={1}>
            <ProgressView/>
        </Box>

    );
};

export default PayInitiator;
